import { DialogUtility } from "@syncfusion/ej2-popups";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, {
  PropsWithChildren,
  Reducer,
  useContext,
  useReducer,
  useRef,
} from "react";
import { RolePermissionsContext } from "../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFDetailFieldCustom from "../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFRecordInfo from "../../../RAFComponents/Navigation/RAFRecordInfo";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  DeleteRecord,
  RafClearDialogContent,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import { hasPermission } from "../../../RAFComponents/helpers/PermissionHelper";
import { isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import RAFEdit from "../../Common/Update";
import DOMPurify from "dompurify";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import { RAFButtonConstant } from "../../../constants/Common/Constants";

interface IProps {
  triggerRow: any;
  onSave: () => void;
  triggerEntity: EntityRow;
  uiMode?: "CardView" | "ListView";
  allowEdit?: boolean;
}

interface IState {
  showUpdateTrigger: boolean;
}

function TriggerContent({
  triggerRow,
  uiMode = "CardView",
  ...props
}: PropsWithChildren<IProps>) {
  let deleteDialog: any;
  const triggerModule = CareEsioEntity.CareRecipientTrigger.EntityName;

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;
  const hasPermissionToEdit = hasPermission(
    permissionValue,
    `${triggerModule}::Update`
  );

  let updateTriggerDialogRef = useRef<DialogComponent>(null);

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      showUpdateTrigger: false,
    }
  );

  //UpdateTriggerRegiester start
  const showUpdateUpdateTriggerDialog = () => {
    if (hasPermissionToEdit) {
      setState({ showUpdateTrigger: true });
    }
  };

  const updateUpdateTriggerContent = () => {
    if (state.showUpdateTrigger) {
      return (
        <RAFEdit
          objectUID={triggerRow.UID}
          moduleName={triggerModule}
          onSave={refreshOnUpdate.bind(this)}
          onClose={closeUpdateTriggerRegiesterDialog.bind(this)}
          onDelete={onClickDeleteSection}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  };

  const refreshOnUpdate = async () => {
    await RafClearDialogContent(updateTriggerDialogRef);
    setState({ showUpdateTrigger: false });
    if (props.onSave) {
      props.onSave();
    }
  };

  const closeUpdateTriggerRegiesterDialog = async () => {
    await RafClearDialogContent(updateTriggerDialogRef);
    setState({ showUpdateTrigger: false });
  };
  //UpdateTriggerRegiester end

  //delete item start
  const onClickDeleteSection = () => {
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: deleteRecord.bind(this) },
      title: `Delete ${CareEsioEntity.CareRecipientTrigger.DisplayName}`,
      position: { X: "center", Y: "center" },
      cssClass: `alert-dialog deleteDialog`,
    });
  };

  async function deleteRecord() {
    let progressDiv = showProgress(".deleteDialog.e-dialog");
    let isDeleted = await DeleteRecord(
      triggerRow.UID,
      triggerModule,
      props.triggerEntity.UID
    );

    if (isDeleted) {
      hideProgress(progressDiv);
      deleteDialog.hide();
      refreshOnUpdate();
    } else {
      hideProgress(progressDiv);
      deleteDialog.hide();
      showWarningToast("Sorry something went wrong !");
    }
  }
  //delete item end

  const Severity = (colClassName?) => {
    return (
      <RAFDetailFieldCustom
        value={
          isNotNullAndUndefined(triggerRow.Severity)
            ? triggerRow.Severity
            : "Not set"
        }
        title="Severity"
        moduleName={triggerModule}
        isColorOption
        field="Severity"
        showLabel={false}
        mode="outlineView"
        {...isNotNullAndUndefined(colClassName) && { colClassName: colClassName }}
        valueClassName="raf_badge"
      />
    );
  }

  const Frequency = (colClassName?) => {
    return (
      <RAFDetailFieldCustom
        value={
          isNotNullAndUndefined(triggerRow.Frequency)
            ? triggerRow.Frequency
            : "Not set"
        }
        title="Frequency"
        moduleName={triggerModule}
        isColorOption
        field="Frequency"
        showLabel={false}
        mode="outlineView"
        {...isNotNullAndUndefined(colClassName) && { colClassName: colClassName }}
        valueClassName="raf_badge"
      />
    );
  }

  let customTitle = (
    <div>
      <div className="row g-3">
        <div className="col-12">
          <span className="subtitle_1 semi_bold">{triggerRow.Title}</span>
        </div>
        <div className="col-12">
          <div className="row g-3">
            <div className="col-12">
              <CustomCardWidget
                cardContentClassName="p-2"
              >
                <RAFDetailFieldCustom
                  title="SIGNS & SYMPTOMS"
                  value={triggerRow.SignsSymptoms}
                  rowClassName="gx-0 gy-1"
                ></RAFDetailFieldCustom>
              </CustomCardWidget>
            </div>
            <div className="col-12">
              <CustomCardWidget
                cardContentClassName="p-2"
              >
                <RAFDetailFieldCustom
                  title="ACTIONS/RESPONSE"
                  value={triggerRow.ActionResponse}
                  rowClassName="gx-0 gy-1"
                ></RAFDetailFieldCustom>
              </CustomCardWidget>
            </div>
          </div>
        </div>

      </div>
    </div>
  );

  let customFooter = (
    <div>
      <div className="custom__card__separator custom__card__separator__light"></div>
      <div className="p-3 py-2">
        <div className="row gx-2 align-items-center">
          {isNotNullAndUndefined(triggerRow.Severity) && (
            <div className="col-auto">
              {Severity()}
            </div>
          )}
          {isNotNullAndUndefined(triggerRow.Frequency) && (
            <div className="col-auto">
              {Frequency()}
            </div>
          )}
          <div className="col-auto">
            <RAFRecordInfo
              createdBy={triggerRow.CreatedBy}
              createdDate={triggerRow.CreatedDate}
              modifiedDate={triggerRow.ModifiedDate}
              modifiedBy={triggerRow.ModifiedBy}
              lastActivityDate={triggerRow.LastActivityDate}
              spanContent="Updated "
              dropdownBtnClassName="custom-button-sm"
              spanContentClassName="body_3_light medium"
              hideClockIcon
            />
          </div>
          {props.allowEdit ?
            <div className="col-auto ms-auto">
              <RAFButtonComponent
                action={RAFButtonConstant.Edit}
                iconBtn
                className="btn_state_warning white e-round"
                {...(props.allowEdit
                  ? { onClick: showUpdateUpdateTriggerDialog }
                  : {})}
              ></RAFButtonComponent>
            </div>
            :
            ''
          }
        </div>
      </div>
    </div>
  )

  if (isNotNullAndUndefined(triggerRow)) {
    return (
      <div className="col-12 mb-3" key={triggerRow.UID}>
        {/* <div className={`custom__card clickable shadow-sm ${triggerRow.TriggerLevel}`} onClick={showUpdateUpdateTriggerDialog}>
                    <div className="custom__card__content p-0"> */}
        {uiMode === "CardView" ? (
          <CustomCardWidget
            cardClassName={`${props.allowEdit ? "pointer " : ""}surface_neutral_base overflow-hidden`}
            {...(props.allowEdit
              ? { onClick: showUpdateUpdateTriggerDialog }
              : {})}
            footerTemplate={customFooter}
          >
            {customTitle}
          </CustomCardWidget>
        ) : (
          <div
            className={`border-bottom ${hasPermissionToEdit ? "pointer" : ""}`}
            onClick={showUpdateUpdateTriggerDialog}
          >
            <div
              className="px-3 py-2"
              style={{
                margin: "2px 0",
                borderLeft: "3px solid rgb(187, 187, 187)",
              }}
            >
              {/* <RAFCollapseWithPlusIcon
                            layout={RAFLayout.TwoColumnLayout}
                            collapsePanelHeaderClass={'collapsePanel__header__fixedHeight'}
                            headerFixedHeight
                            customTitle={customTitle}
                            isCollapsed
                        > */}
              {customTitle}
              {customFooter}
              {/* </RAFCollapseWithPlusIcon> */}
            </div>
            {/* </div>
                </div> */}
          </div>
        )}
        {state.showUpdateTrigger && (
          <DialogComponent
            // header={
            //     "Update " +
            //     getDisplayNameByModuleName(triggerModule, props.triggerEntity.DisplayName)
            // }
            header={`Update ${CareEsioEntity.CareRecipientTrigger.DisplayName}`}
            showCloseIcon
            visible={state.showUpdateTrigger}
            cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
            id={`dlg_update_${triggerModule}`}
            content={updateUpdateTriggerContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={closeUpdateTriggerRegiesterDialog.bind(this)}
            zIndex={1200}
            open={PreventFocusOnDialogOpen}
            ref={updateTriggerDialogRef}
          />
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default React.memo(TriggerContent);
