import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, {
    PropsWithChildren,
    Reducer,
    useReducer
} from "react";
import { RRule } from "rrule";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDetailFieldCustom from "../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFDetailsValueWithSeparator from "../../../RAFComponents/Navigation/RAFDetailsValueWithSeparator/RAFDetailsValueWithSeparator";
import RAFRecordInfo from "../../../RAFComponents/Navigation/RAFRecordInfo";
import {
    IsNotNullOrWhiteSpace,
    isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { RAFDataType } from "../../../RAFComponents/models/Common/RAFDataType";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
    MomentFormats,
    RAFButtonConstant
} from "../../../constants/Common/Constants";
import ManageRecurringConfig from "./ManageRecurringConfig";
import { RecurringConfigRow } from "./RecurringConfigRow";

interface IProps {
    recurringConfigRow: RecurringConfigRow;
    onSave?: () => void;
    recurringConfigEntity: EntityRow;

    allowEdit?: boolean;
}

interface IState {
    showUpdateRecurringConfig: boolean;
}

function RecurringConfigCardContent({
    allowEdit = true,
    recurringConfigRow,
    ...props
}: PropsWithChildren<IProps>) {
    let deleteDialog: any;
    const recurringConfigModule =
        CareEsioEntity.RecurringConfig.EntityName;
    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            showUpdateRecurringConfig: false,
        }
    );

    //UpdateRecurringConfig start
    const showUpdateRecurringConfigDialog = () => {
        if (allowEdit) {
            setState({ showUpdateRecurringConfig: true });
        }
    };

    const updateUpdateRecurringConfigCardContent = () => {
        if (state.showUpdateRecurringConfig) {
            return (
                <ManageRecurringConfig
                    objectUID={recurringConfigRow.UID}
                    onSave={refreshOnUpdate}
                    onClose={closeUpdateRecurringConfigDialog}
                    onDelete={refreshOnUpdate}
                    isActive
                />
            );
        } else {
            return <div></div>;
        }
    };

    const refreshOnUpdate = () => {
        setState({ showUpdateRecurringConfig: false });
        if (props.onSave) {
            props.onSave();
        }
    };

    const closeUpdateRecurringConfigDialog = () => {
        setState({ showUpdateRecurringConfig: false });
    };
    //UpdateRecurringConfig end

    if (isNotNullAndUndefined(recurringConfigRow)) {
        const rruleExpression =
            isNotNullAndUndefined(recurringConfigRow) &&
                isNotNullAndUndefined(recurringConfigRow.RruleExpression)
                ? recurringConfigRow.RruleExpression
                : null;
        const ruleString = isNotNullAndUndefined(rruleExpression)
            ? RRule.fromString(rruleExpression)
            : null;

        const Frequency = (colClassName?, labelDivClassName?, valueDivClassName?) => {
            return (
                <RAFDetailFieldCustom
                value={
                    isNotNullAndUndefined(recurringConfigRow.Frequency)
                        ? recurringConfigRow.Frequency
                        : "Not set"
                }
                  title="Frequency"
                  moduleName={CareEsioEntity.RecurringConfig.EntityName}
                  isColorOption
                  field="Frequency"
                  mode="textOnly"
                    {...isNotNullAndUndefined(colClassName) && { colClassName: colClassName }}
                    {...isNotNullAndUndefined(labelDivClassName) && { labelDivClassName: labelDivClassName }}
                    {...isNotNullAndUndefined(valueDivClassName) && { valueDivClassName: valueDivClassName }}
                  labelClassName="text-transform-none details-label"
                  rowClassName="gx-0 gy-1 flex-column"
                  showLabel={false}
                />
            );
        };

        const customTitle = (
            <>
                <div className="col d-flex align-items-center">

                    <div className="d-flex align-items-center">
                        <span className={"subtitle_1"}>
                            {recurringConfigRow.EntityDisplayName}
                        </span>
                        {isNotNullAndUndefined(recurringConfigRow.IsRequired) &&
                            recurringConfigRow.IsRequired === true && (
                                <span className="raf_badge raf_xsm raf_badge_danger ms-2">Must-do</span>
                            )}
                    </div>
                </div>
                {IsNotNullOrWhiteSpace(recurringConfigRow.StartDate) || IsNotNullOrWhiteSpace(recurringConfigRow.EndDate) && (
                    <div className="col-12 mt-2">
                        <RAFDetailsValueWithSeparator
                            outerClassName="h-auto"
                            rowClassName="flex-nowrap g-2"
                        >
                            {IsNotNullOrWhiteSpace(recurringConfigRow.StartDate) && (
                                <RAFDetailFieldCustom
                                    title="Start Date:"
                                    value={recurringConfigRow.StartDate}
                                    labelDivClassName="col-auto"
                                    valueDivClassName="col-auto"
                                    labelClassName="body_2_light medium"
                                    valueClassName="subtitle_2"
                                    rowClassName="gx-2"
                                    fieldFormat={{ type: RAFDataType.Date, format: MomentFormats.DATE }}
                                ></RAFDetailFieldCustom>
                            )}
                            {IsNotNullOrWhiteSpace(recurringConfigRow.EndDate) && (
                                <RAFDetailFieldCustom
                                    title="End Date:"
                                    value={recurringConfigRow.EndDate}
                                    labelDivClassName="col-auto"
                                    valueDivClassName="col-auto"
                                    labelClassName="body_2_light medium"
                                    valueClassName="subtitle_2"
                                    rowClassName="gx-2"
                                    fieldFormat={{ type: RAFDataType.Date, format: MomentFormats.DATE }}
                                ></RAFDetailFieldCustom>
                            )}
                        </RAFDetailsValueWithSeparator>
                    </div>
                )}
                <div className="col-md-12">
                <RAFDetailsValueWithSeparator
                            outerClassName="line-seprator h-auto"
                            rowClassName="flex-nowrap gx-3"

                        >
                            {Frequency(null, 'col-auto', 'col-auto')}
                            <RAFRecordInfo
                                createdBy={recurringConfigRow.CreatedBy}
                                createdDate={recurringConfigRow.CreatedDate}
                                modifiedDate={recurringConfigRow.ModifiedDate}
                                modifiedBy={recurringConfigRow.ModifiedBy}
                                lastActivityDate={recurringConfigRow.LastActivityDate}
                                spanContent="Updated "
                                dropdownBtnClassName="custom-button-sm"
                                spanContentClassName="body_3_light medium"
                                hideClockIcon
                            />
                        </RAFDetailsValueWithSeparator>
                </div>
                {/* <div className="col-12 mt-3">
                    <div className="d-none d-xl-block">
                        <RAFDetailsValueWithSeparator
                            outerClassName="line-seprator h-auto"
                            rowClassName="flex-nowrap gx-3"

                        >
                            {Frequency(null, 'col-auto', 'col-auto')}
                        </RAFDetailsValueWithSeparator>
                    </div>
                    <div className="d-xl-none">
                        <div className="row g-2 g-md-3">
                            {Frequency('col-12 col-md-6')}
                        </div>
                    </div>
                </div> */}
            </>
        );

        let customFooter = (
            <div
                onClick={showUpdateRecurringConfigDialog}
            >
                <div className="custom__card__separator custom__card__separator__light"></div>
                <div className="px-3 py-2">
                    <div className="row gx-2 align-items-center">
                        <div className="col-auto">
                        <RAFDetailsValueWithSeparator
                            outerClassName="line-seprator h-auto"
                            rowClassName="flex-nowrap gx-3"

                        >
                            {Frequency(null, 'col-auto', 'col-auto')}
                            <RAFRecordInfo
                                createdBy={recurringConfigRow.CreatedBy}
                                createdDate={recurringConfigRow.CreatedDate}
                                modifiedDate={recurringConfigRow.ModifiedDate}
                                modifiedBy={recurringConfigRow.ModifiedBy}
                                lastActivityDate={recurringConfigRow.LastActivityDate}
                                spanContent="Updated "
                                dropdownBtnClassName="custom-button-sm"
                                spanContentClassName="body_3_light medium"
                                hideClockIcon
                            />
                        </RAFDetailsValueWithSeparator>

                        </div>
                    </div>
                </div>
            </div>
        );

        const customCard = (
            <div
                className={`p-3${allowEdit ? " pointer" : ""}`}
                onClick={showUpdateRecurringConfigDialog}
            >
                <div className="row g-2">{customTitle}</div>
            </div>
        );

        return (
            <div
                className={
                    "col-12"
                }
                key={recurringConfigRow.UID}
            >
                <CustomCardWidget
                    removeContentPadding
                    // footerTemplate={customFooter}
                    cardClassName={`surface_neutral_base overflow-hidden`}
                >
                    {customCard}
                </CustomCardWidget>
                {state.showUpdateRecurringConfig && (
                    <DialogComponent
                        header={`Update ${CareEsioEntity.RecurringConfig.DisplayName}`}
                        showCloseIcon
                        visible={state.showUpdateRecurringConfig}
                        cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
                        id={`dlg_update_${recurringConfigModule}`}
                        content={updateUpdateRecurringConfigCardContent}
                        isModal
                        target="body"
                        closeOnEscape={false}
                        close={closeUpdateRecurringConfigDialog}
                        zIndex={1200}
                        open={PreventFocusOnDialogOpen}
                    ></DialogComponent>
                )}
            </div>
        );
    } else {
        return <div></div>;
    }
}

export default React.memo(RecurringConfigCardContent);