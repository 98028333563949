import { DialogUtility } from "@syncfusion/ej2-popups";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, { PropsWithChildren, Reducer, useContext, useReducer, useRef } from "react";
import { RolePermissionsContext } from "../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFDetailFieldCustom from "../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFRecordInfo from "../../../RAFComponents/Navigation/RAFRecordInfo";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import { DeleteRecord, RafClearDialogContent, hideProgress, showProgress } from "../../../RAFComponents/helpers/AppHelper";
import { hasPermission } from "../../../RAFComponents/helpers/PermissionHelper";
import { IsNotNullOrWhiteSpace, isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import RAFEdit from "../../Common/Update";

interface IProps {
    preferenceRow: any;
    onSave: () => void;
    preferenceEntity: EntityRow;
    uiStyle?: 'CardStyle' | 'ListStyle';
    allowEdit?: boolean;
    cardClassName?: string;
}

interface IState {
    showUpdatePreference: boolean;
}

function PreferenceContent({
    preferenceRow,
    uiStyle = "ListStyle",
    ...props
}: PropsWithChildren<IProps>) {
    let deleteDialog: any;
    const preferenceModule = CareEsioEntity.CareRecipientPreference.EntityName;
    let updatePreferenceDialogRef = useRef<DialogComponent>(null);

    const rolePermissionsContext = useContext(RolePermissionsContext);
    const permissionValue = isNotNullAndUndefined(rolePermissionsContext) ? rolePermissionsContext.permissionValue : null;
    const hasPermissionToEdit = hasPermission(permissionValue, `${preferenceModule}::Update`);

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            showUpdatePreference: false
        }
    );

    //UpdatePreferenceRegiester start
    const showUpdatePreferenceDialog = () => {
        if (hasPermissionToEdit) {
            setState({ showUpdatePreference: true });
        };
    };

    const updatePreferenceContent = () => {
        if (state.showUpdatePreference) {
            return (
                <RAFEdit
                    objectUID={preferenceRow.UID}
                    moduleName={preferenceModule}
                    onSave={refreshOnUpdate.bind(this)}
                    onClose={closeUpdatePreferenceRegiesterDialog.bind(this)}
                    onDelete={onClickDeleteSection}
                    isActive
                />
            );
        } else {
            return (
                <div></div>
            );
        }
    };

    const refreshOnUpdate = async () => {
        await RafClearDialogContent(updatePreferenceDialogRef);
        setState({ showUpdatePreference: false });
        if (props.onSave) {
            props.onSave();
        }
    };

    const closeUpdatePreferenceRegiesterDialog = async () => {
        await RafClearDialogContent(updatePreferenceDialogRef);
        setState({ showUpdatePreference: false });
    };
    //UpdatePreferenceRegiester end

    //delete item start
    const onClickDeleteSection = () => {
        deleteDialog = DialogUtility.confirm({
            animationSettings: { effect: "Fade" },
            cancelButton: { text: "No" },
            closeOnEscape: false,
            content: "Are you sure want to delete?",
            okButton: { text: "Yes", click: deleteRecord.bind(this) },
            title: `Delete ${CareEsioEntity.CareRecipientPreference.DisplayName}`,
            position: { X: "center", Y: "center" },
            cssClass: `alert-dialog deleteDialog`,
        });
    };

    async function deleteRecord() {
        let progressDiv = showProgress(".deleteDialog.e-dialog");
        let isDeleted = await DeleteRecord(preferenceRow.UID, preferenceModule, props.preferenceEntity.UID);

        if (isDeleted) {
            hideProgress(progressDiv);
            deleteDialog.hide();
            refreshOnUpdate();
        } else {
            hideProgress(progressDiv);
            deleteDialog.hide();
            showWarningToast("Sorry something went wrong !");
        }
    }
    //delete item end

    const preferenceContentItemContent = () => {
        return (

            <div>
                <div>
                    <span className="header-text">{preferenceRow.Title}</span>
                </div>
                <div className="detailsLeftCard-second-title h-26 mt-2">
                    <div className="w-100">
                        <div className="row gx-3 flex-nowrap align-items-center">
                            {(isNotNullAndUndefined(preferenceRow.PreferenceType) || isNotNullAndUndefined(preferenceRow.Intensity)) && (
                                <div className="col-auto detailsLeftCard-second-title-col">
                                    <div className="detailsLeftCard-second-title-item">
                                        <div className="row gx-1">
                                            {IsNotNullOrWhiteSpace(preferenceRow.PreferenceType) &&
                                                <div className="col-auto">
                                                    <RAFDetailFieldCustom
                                                        value={
                                                            isNotNullAndUndefined(preferenceRow.PreferenceType)
                                                                ? preferenceRow.PreferenceType
                                                                : "Not set"
                                                        }
                                                        title="PreferenceType"
                                                        moduleName={preferenceModule}
                                                        isColorOption
                                                        field="PreferenceType"
                                                        showLabel={false}
                                                        mode="outlineView"
                                                    />
                                                </div>
                                            }
                                            {isNotNullAndUndefined(preferenceRow.Intensity) && (
                                                <div className="col-auto">
                                                    <RAFDetailFieldCustom
                                                        value={
                                                            isNotNullAndUndefined(preferenceRow.Intensity)
                                                                ? preferenceRow.Intensity
                                                                : "Not set"
                                                        }
                                                        title="Intensity"
                                                        moduleName={preferenceModule}
                                                        isColorOption
                                                        field="Intensity"
                                                        showLabel={false}
                                                        mode="outlineView"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="col-auto detailsLeftCard-second-title-col">
                                <div
                                    className="detailsLeftCard-second-title-item"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <RAFRecordInfo
                                        createdBy={preferenceRow.CreatedBy}
                                        createdDate={preferenceRow.CreatedDate}
                                        modifiedDate={preferenceRow.ModifiedDate}
                                        modifiedBy={preferenceRow.ModifiedBy}
                                        lastActivityDate={preferenceRow.LastActivityDate}
                                        dropdownBtnClassName="custom-button-sm"
                                        hideClockIcon
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            // <div className="row g-1 align-items-center justify-content-between">
            //     <div className="col d-flex align-items-center">
            //         <div><span className="header-text-sm">{preferenceRow.Title}</span></div>
            //     </div>

            //     <div className="col-12">
            //         <div className="detailsLeftCard-second-title h-auto">
            //             <div className="w-100">
            //                 <div className="row gx-2 flex-nowrap align-items-center">
            //                     {isNotNullAndUndefined(preferenceRow.PreferenceType) && (
            //                         <div className="col-auto detailsLeftCard-second-title-col">
            //                             <div className="detailsLeftCard-second-title-item">
            //                                 <RAFDetailFieldCustom
            //                                     field="PreferenceType"
            //                                     title="Preference Type"
            //                                     value={preferenceRow.PreferenceType}
            //                                     moduleName={preferenceModule}
            //                                     showLabel={false}
            //                                     isColorOption
            //                                 ></RAFDetailFieldCustom>
            //                                 {/* {preferenceRow.PreferenceType} */}
            //                             </div>
            //                         </div>
            //                     )}
            //                     {isNotNullAndUndefined(preferenceRow.Intensity) && (
            //                         <div className="col-auto detailsLeftCard-second-title-col">
            //                             <div className="detailsLeftCard-second-title-item">
            //                                 <RAFDetailFieldCustom
            //                                     field="Intensity"
            //                                     title="Intensity"
            //                                     value={preferenceRow.Intensity}
            //                                     moduleName={preferenceModule}
            //                                     showLabel={false}
            //                                     isColorOption
            //                                 ></RAFDetailFieldCustom>
            //                                 {/* {preferenceRow.Intensity} */}
            //                             </div>
            //                         </div>
            //                     )}
            //                     <div className="col-auto detailsLeftCard-second-title-col">
            //                         <div className="detailsLeftCard-second-title-item"
            //                             onClick={(e) => e.stopPropagation()}
            //                         >
            //                             <RAFRecordInfo
            //                                 createdBy={preferenceRow.CreatedBy}
            //                                 createdDate={preferenceRow.CreatedDate}
            //                                 modifiedDate={preferenceRow.ModifiedDate}
            //                                 modifiedBy={preferenceRow.ModifiedBy}
            //                                 lastActivityDate={preferenceRow.LastActivityDate}
            //                                 dropdownBtnClassName="custom-button-sm"
            //                                 hideClockIcon
            //                             />
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            // </div>
        );
    };

    if (isNotNullAndUndefined(preferenceRow)) {
        return (
            <div className="col-12" key={preferenceRow.UID}>
                {/* <div className={`custom__card custom__card__leftBorder clickable shadow-sm ${preferenceRow.PreferenceLevel}`} onClick={showUpdatePreferenceDialog}>
                    <div className="custom__card__content p-3 py-2"> */}
                {uiStyle === "ListStyle" ?
                    <CustomCardWidget
                        cardClassName={`${IsNotNullOrWhiteSpace(props.cardClassName) ? props.cardClassName : ''}${props.allowEdit && hasPermissionToEdit ? (IsNotNullOrWhiteSpace(props.cardClassName) ? " pointer" : 'pointer') : ""}`}
                        // cardClassName={`${props.allowEdit && hasPermissionToEdit ? 'pointer ' : ''}care_recipient_care_plan_item`}
                        {...props.allowEdit ? { onClick: showUpdatePreferenceDialog } : {}}
                    //  onClick={showUpdatePreferenceDialog}
                    >{preferenceContentItemContent()}</CustomCardWidget>
                    :
                    <div className={`border-bottom ${hasPermissionToEdit ? 'pointer' : ''}`}
                        {...props.allowEdit ? { onClick: showUpdatePreferenceDialog } : {}}
                    // onClick={showUpdatePreferenceDialog}
                    >
                        <div className="px-3 py-2" style={{ margin: '2px 0', borderLeft: '3px solid rgb(187, 187, 187)' }}>
                            {preferenceContentItemContent()}
                        </div>
                    </div>
                }
                {/* </div>
                </div> */}
                {state.showUpdatePreference && (
                    <DialogComponent
                        // header={
                        //     "Update " +
                        //     getDisplayNameByModuleName(preferenceModule, props.preferenceEntity.DisplayName)
                        // }
                        header={
                            `Update ${CareEsioEntity.CareRecipientPreference.DisplayName}`
                        }
                        showCloseIcon
                        visible={state.showUpdatePreference}
                        cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
                        id={`dlg_update_${preferenceModule}`}
                        content={updatePreferenceContent.bind(this)}
                        isModal
                        target="body"
                        closeOnEscape={false}
                        close={closeUpdatePreferenceRegiesterDialog.bind(this)}
                        zIndex={1200}
                        open={PreventFocusOnDialogOpen}
                        ref={updatePreferenceDialogRef}
                    />
                )}
            </div>
        );
    } else {
        return (
            <div></div>
        );
    }
}

export default React.memo(PreferenceContent);