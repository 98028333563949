import { DialogComponent, DialogUtility } from "@syncfusion/ej2-react-popups";
import React, {
  PropsWithChildren,
  Reducer,
  Suspense,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import RAFFieldStateProvider from "../../../RAFComponents/Grid/RAFFieldStateProvider";
import RAFGrid4 from "../../../RAFComponents/Grid/RAFGrid4";
import RAFGridColumn from "../../../RAFComponents/Grid/RAFGridColumn";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFCollapse from "../../../RAFComponents/Navigation/RAFCollapse";
import RAFCollapseWithPlusIcon from "../../../RAFComponents/Navigation/RAFCollapseWithPlusIcon";
import RAFDetailFieldEditableCustom from "../../../RAFComponents/Navigation/RAFDetailFieldEditableCustom";
import RAFEmptyState from "../../../RAFComponents/Navigation/RAFEmptyState";
import RAFPanel from "../../../RAFComponents/Navigation/RAFPanel";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  DeleteRecord,
  RafClearDialogContent,
  getAllAttributes,
  getFields,
  getRelatedRecords,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  ConvertSystemName,
  IsNotNullOrWhiteSpace,
  getSaveRequest,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { RAFDataType } from "../../../RAFComponents/models/Common/RAFDataType";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { getEntityByObjectName } from "../../../RAFMaster/helpers/RMutils";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareRecipientPreferencePermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  Constants,
  ContentType,
  RAFButtonConstant,
  RAFLayout,
} from "../../../constants/Common/Constants";
import RAFGridTemplates, {
  getGridColumnTemplatesByModule,
} from "../../../helpers/RAFGridTemplates";
import RAFCreate from "../../Common/Create";
import { RAFObjectContext } from "../../Common/Providers/RAFObjectContextProvider";
import RAFEdit from "../../Common/Update";
import RAFRecordCount from "../CareRecipient/Details/RAFRecordCount";
import UpdateCareRecipient from "../CareRecipient/Details/UpdateCareRecipient";
import PreferenceContent from "./PreferenceContent";
//const RAFGrid4 = React.lazy(() => import('../../../RAFComponents/Grid/RAFGrid4'));

interface IProps {
  careRecipientUID: string;
  showAddBtn?: boolean;
  uiStyle?: "CardStyle" | "DetailsStyle";
  paddingClass?: string;
  careRecipientRow?: string;
}

interface IState {
  isLoading: boolean;
  preferenceItems: any;
  preferenceEntity: EntityRow;
  showCreatePreferenceContent: boolean;
  showUpdatePreferenceContent: boolean;
  preferenceRow: any;
  preferenceDivKey: number;
  preferenceType:
  | "like"
  | "dislike"
  | "Happy"
  | "Relax"
  | "Stress"
  | "Frustrated / Angry";
}

function PreferenceList({
  showAddBtn = true,
  paddingClass = "p-2 p-md-3",
  ...props
}: PropsWithChildren<IProps>) {
  const preferenceModule = CareEsioEntity.CareRecipientPreference.EntityName;
  let deleteDialog: any;
  let preferenceListDialogRef = useRef<DialogComponent>(null);
  const rafObject = React.useContext(RAFObjectContext);
  const careRecipientRow = isNotNullAndUndefined(rafObject)
    ? rafObject.rafObject
    : props.careRecipientRow;
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      preferenceItems: null,
      preferenceEntity: null,
      showCreatePreferenceContent: false,
      showUpdatePreferenceContent: false,
      preferenceRow: null,
      preferenceDivKey: Math.random(),
      preferenceType: null,
    }
  );

  useEffect(() => {
    refresh();
  }, [props.careRecipientUID]);

  const refresh = async () => {
    await RafClearDialogContent(preferenceListDialogRef);
    if (isNotNullAndUndefined(props.careRecipientUID)) {
      setState({
        isLoading: true,
        preferenceItems: null,
        showCreatePreferenceContent: false,
      });

      const [preferenceEntity, attributeRelatedList, queryAttributes] = await Promise.all([
        getEntityByObjectName({
          ObjectName: ConvertSystemName(preferenceModule),
        }),
        getAllAttributes(ConvertSystemName(preferenceModule)),
        getFields(ConvertSystemName(preferenceModule)),
      ]);//dont remove this line  details filed is used in loop below this line add to avoid multiple calls

      const preferenceItems = await getRelatedRecords(
        preferenceModule,
        null,
        "CareRecipientUID",
        props.careRecipientUID,
        preferenceEntity.UID
      );

      setState({ isLoading: false, preferenceItems, preferenceEntity });
      // setState({ isLoading: false, preferenceEntity });
    }
  };

  const createPreferenceDlgDiv = () => {
    return (
      <div>
        {state.showCreatePreferenceContent && (
          <DialogComponent
            header={`Add ${CareEsioEntity.CareRecipientPreference.DisplayName}`}
            showCloseIcon
            visible={state.showCreatePreferenceContent}
            cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
            id={`dlg_create_${preferenceModule}`}
            content={createPreferenceContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={closeCreatePreferenceDialog.bind(this)}
            zIndex={1200}
            open={PreventFocusOnDialogOpen}
            ref={preferenceListDialogRef}
          ></DialogComponent>
        )}
      </div>
    );
  };

  const getPreferenceListContent = () => {
    if (props.uiStyle === "DetailsStyle") {
      return (
        <div>
          {getCareRecipientContent()}
          {/* {preferenceListItem()}
          {state.isLoading === false && showAddBtn === true && (
            <div className="col-12">
              <RAFPermissionRender
                permissionName={
                  CareRecipientPreferencePermissionConstants.CareRecipientPreferenceAdd
                }
              >
                <RAFButtonComponent
                  className="btn-dashed btn-roundedCorner w-100 e-outline"
                  isPrimary
                  // className="custom-button-md"
                  action={RAFButtonConstant.Add}
                  btnContent={`Add ${CareEsioEntity.CareRecipientPreference.DisplayName}`}
                  onClick={() => onClickAddPreference()}
                />
              </RAFPermissionRender>
              {createPreferenceDlgDiv()}
            </div>
          )} */}
        </div>
      );
    } else {
      const showExpandBtn = state.isLoading
        ? true
        : isNotEmptyArray(state.preferenceItems)
          ? true
          : false;
      return (
        <CustomCardWidget removeContentPadding>
          <RAFCollapseWithPlusIcon
            toggleArrowIcon="Arrow"
            title={CareEsioEntity.CareRecipientPreference.CollectionName}
            collapsePanelHeaderClass={"collapsePanel__header__fixedHeight__lg"}
            // collapsePanelHeaderClass={"px-0"}
            allowFullRowClick
            collapsePanelContentClassname="p-0"
            badge={{
              count:
                IsNotNullOrWhiteSpace(state.preferenceItems) &&
                  IsNotNullOrWhiteSpace(state.preferenceItems.length)
                  ? state.preferenceItems.length
                  : 0,
              show: true,
              className: "ms-2 raf-badge-circle raf-badge-tertiary-circle",
            }}
            isCollapsed={true}
            // collapsible={props.collapsible}
            showSeparator={true}
            customButton={
              <div>
                {showAddBtn === true && (
                  <div className="d-flex align-items-baseline">
                    <div className="col-auto">
                      <RAFPermissionRender
                        permissionName={
                          CareRecipientPreferencePermissionConstants.CareRecipientPreferenceAdd
                        }
                      >
                        <RAFButtonComponent
                          className={`custom-button-sm primary-custom-button`}
                          // className={`custom-button-sm primary-custom-button${!BrowserIsDevice ? " min-width-lg" : ""}`}
                          // className="custom-button-md"
                          action={RAFButtonConstant.Add}
                          // {...!BrowserIsDevice ?
                          //   { btnContent: `Add ${CareEsioEntity.CareRecipientPreference.DisplayName}` }
                          //   :
                          //   {}
                          // }
                          onClick={() => onClickAddPreference()}
                        />
                      </RAFPermissionRender>
                    </div>
                  </div>
                )}
              </div>
            }
          >
            <div>
              {createPreferenceDlgDiv()}
              {preferenceListItem()}
            </div>
          </RAFCollapseWithPlusIcon>
        </CustomCardWidget>
      );
    }
  };

  const preferenceListItem = () => {
    if (state.isLoading === false) {
      if (isNotEmptyArray(state.preferenceItems)) {
        return (
          <div>
            <div
              className={`custom__card__content ${paddingClass}`}
              key={state.preferenceDivKey}
            >
              <div className="row gx-0 gy-2 gy-md-3">
                {state.preferenceItems.map((item) => {
                  return (
                    <PreferenceContent
                      key={item.UID}
                      preferenceRow={item}
                      preferenceEntity={state.preferenceEntity}
                      allowEdit
                      onSave={refresh}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="custom__card__content p-0">
            <div className="row g-3">
              <div className="d-flex align-items-center justify-content-center flex-column">
                <div className="my-3">
                  <RAFEmptyState
                    title={`No ${CareEsioEntity.CareRecipientPreference.DisplayName}`}
                    {...(showAddBtn === true && {
                      body: `Please click on add button to add ${CareEsioEntity.CareRecipientPreference.DisplayName}.`,
                    })}
                    displayStyle="TextOnly"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="custom__card__content p-0">
          <div className="row g-3">
            <div className="container-fluid px-0">
              <ACLoadingPanel loadingText="Preparing Data..." />
            </div>
          </div>
        </div>
      );
    }
  };

  const preferenceListItems = (
    preferenceType:
      | "like"
      | "dislike"
      | "Happy"
      | "Relax"
      | "Stress"
      | "Frustrated / Angry"
  ) => {
    let { isLoading, preferenceItems, preferenceDivKey, preferenceEntity } =
      state;
    if (isLoading === false) {
      if (isNotEmptyArray(preferenceItems)) {
        let filteredPreferenceItems = preferenceItems.filter(
          (x) => x.PreferenceType === preferenceType
        );
        if (isNotEmptyArray(filteredPreferenceItems)) {
          return (
            <div>
              <div
                className={`custom__card__content ${paddingClass}`}
                key={preferenceDivKey}
              >
                <div className="row gx-0 gy-2 gy-md-3">
                  {filteredPreferenceItems.map((item) => {
                    return (
                      <PreferenceContent
                        key={item.UID}
                        preferenceRow={item}
                        preferenceEntity={preferenceEntity}
                        allowEdit
                        onSave={refresh}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          );
        }
      }
      // else {
      //   return (
      //     <div className="custom__card__content p-0">
      //       <div className="row g-3">
      //         <div className="d-flex align-items-center justify-content-center flex-column">
      //           <div className="my-3">
      //             <RAFEmptyState
      //               title={`No ${CareEsioEntity.CareRecipientPreference.DisplayName}`}
      //               {...(showAddBtn === true && {
      //                 body: `Please click on add button to add ${CareEsioEntity.CareRecipientPreference.DisplayName}.`,
      //               })}
      //               displayStyle="TextOnly"
      //             />
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   );
      // }
    } else {
      return (
        <div className="custom__card__content p-0">
          <div className="row g-3">
            <div className="container-fluid px-0">
              <ACLoadingPanel loadingText="Preparing Data..." />
            </div>
          </div>
        </div>
      );
    }
  };

  const preferenceListItem2 = () => {
    let { preferenceEntity, preferenceDivKey } = state;
    return (
      <div key={preferenceDivKey}>
        <div className="e-card">
          <div className="e-card-content p-0">
            <RAFCollapse
              title={"Preferences"}
              contentCardClassName="relatedListOuterDiv"
              //iconName={getModuleAvatarIcon(section.RelatedEntity)}
              customButton={
                <div className="d-flex align-items-center flex-nowrap">
                  <RAFButtonComponent
                    className="custom-button-md d-none primary-custom-button d-sm-inline-block"
                    action={RAFButtonConstant.Add}
                    onClick={() => onClickAddPreference()}
                  ></RAFButtonComponent>
                  <RAFButtonComponent
                    className="custom-button-md d-sm-none primary-custom-button"
                    action={RAFButtonConstant.Add}
                    onClick={() => onClickAddPreference()}
                    iconBtn
                  ></RAFButtonComponent>
                </div>
              }
              IsCollapsed={false}
              arrowPosistion="title"
              headerBadge={
                <RAFRecordCount
                  relatedField={"CareRecipientUID"}
                  relatedFieldValue={props.careRecipientUID}
                  entityUID={preferenceEntity && preferenceEntity.UID}
                />
              }
              //loadChildrenOnExpand
              Collapsible={false}
            >
              <div>
                <Suspense fallback={<div>Loading...</div>}>
                  <RAFFieldStateProvider
                    moduleName={preferenceModule}
                    viewName={`related_${preferenceModule}`}
                  >
                    <RAFGridTemplates>
                      <RAFGrid4
                        gridId={`grid3_${preferenceModule}_mainList`}
                        url={`${Constants.baseAPIUrl}DataList/RelatedList`}
                        additionalParams={[
                          {
                            key: "RelatedField",
                            value: "CareRecipientUID",
                          },
                          {
                            key: "RelatedFieldValue",
                            value: props.careRecipientUID,
                          },
                          {
                            key: "EntityUID",
                            value: preferenceEntity && preferenceEntity.UID,
                          },
                        ]}
                        moduleName={preferenceModule}
                        isRemote
                        rowClick={rowClick}
                        editClick={rowClick}
                        showEditColumn={false}
                        allowEditing
                        allowSelection={false}
                        allowFiltering={false}
                        gridTemplates={getGridColumnTemplatesByModule(
                          preferenceModule
                        )}
                        emptyStateProps={{
                          body: `Get started by adding a triggers!`,
                          iconClass: `fa fa-entity fa-${preferenceModule}`,
                        }}
                        //filterBarVisibile={false}
                        isDynamic
                      >
                        <RAFGridColumn
                          field="Title"
                          headerText="Title"
                          width={"120px"}
                          minWidth={"120px"}
                        // maxWidth={"120px"}
                        />
                        <RAFGridColumn
                          field="PreferenceType"
                          headerText="Type"
                          width={"120px"}
                          minWidth={"120px"}
                        // maxWidth={"120px"}
                        />
                        <RAFGridColumn
                          field="Intensity"
                          headerText="Intensity"
                          width={"120px"}
                          minWidth={"120px"}
                        // maxWidth={"120px"}
                        />
                      </RAFGrid4>
                    </RAFGridTemplates>
                  </RAFFieldStateProvider>
                </Suspense>
                <div>
                  {state.showCreatePreferenceContent && (
                    <DialogComponent
                      header={`Add ${CareEsioEntity.CareRecipientPreference.DisplayName}`}
                      showCloseIcon
                      visible={state.showCreatePreferenceContent}
                      cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
                      id={`dlg_create_${preferenceModule}`}
                      content={createPreferenceContent.bind(this)}
                      isModal
                      target="body"
                      closeOnEscape={false}
                      close={closeCreatePreferenceDialog.bind(this)}
                      zIndex={1200}
                      open={PreventFocusOnDialogOpen}
                      ref={preferenceListDialogRef}
                    ></DialogComponent>
                  )}
                  {state.showUpdatePreferenceContent && (
                    <DialogComponent
                      header={`Update ${CareEsioEntity.CareRecipientPreference.DisplayName}`}
                      showCloseIcon
                      visible={state.showUpdatePreferenceContent}
                      cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
                      id={`dlg_update_${preferenceModule}`}
                      content={updateUpdatePreferenceContent.bind(this)}
                      isModal
                      target="body"
                      closeOnEscape={false}
                      close={closeUpdatePreferenceRegiesterDialog.bind(this)}
                      zIndex={1200}
                      open={PreventFocusOnDialogOpen}
                      ref={preferenceListDialogRef}
                    ></DialogComponent>
                  )}
                </div>
              </div>
            </RAFCollapse>
          </div>
        </div>
      </div>
    );
  };

  //create start

  //addPreference start
  const onClickAddPreference = (
    preferenceType?:
      | "like"
      | "dislike"
      | "Happy"
      | "Relax"
      | "Stress"
      | "Frustrated / Angry"
  ) => {
    setState({ showCreatePreferenceContent: true, preferenceType });
  };

  const createPreferenceContent = () => {
    if (state.showCreatePreferenceContent) {
      let initialValues = {};

      const objectContext = React.useContext(RAFObjectContext);
      const rafObject = isNotNullAndUndefined(objectContext)
        ? objectContext.rafObject
        : null;
      if (isNotNullAndUndefined(rafObject)) {
        initialValues["CareRecipientUID"] = rafObject.UID;
        initialValues["CareRecipient"] = rafObject.first_name;
        initialValues["PreferenceType"] = state.preferenceType;
      }

      return (
        <RAFCreate
          initialValues={initialValues}
          onSave={refreshOnCreate.bind(this)}
          onClose={closeCreatePreferenceDialog.bind(this)}
          moduleName={preferenceModule}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  };

  const refreshOnCreate = async () => {
    await RafClearDialogContent(preferenceListDialogRef);
    refresh();
    setState({
      showCreatePreferenceContent: false,
      preferenceDivKey: Math.random(),
    });
  };

  const onCreatePreferenceList = () => {
    refresh();
  };

  const closeCreatePreferenceDialog = async () => {
    await RafClearDialogContent(preferenceListDialogRef);
    setState({ showCreatePreferenceContent: false });
  };

  //addPreference end
  //create end

  //UpdatePreferenceRegiester start
  const rowClick = (e: Object) => {
    setState({ showUpdatePreferenceContent: true, preferenceRow: e });
  };

  const updateUpdatePreferenceContent = () => {
    let { preferenceRow } = state;
    if (state.showUpdatePreferenceContent) {
      return (
        <RAFEdit
          objectUID={preferenceRow.UID}
          moduleName={preferenceModule}
          onSave={refreshOnUpdate.bind(this)}
          onClose={closeUpdatePreferenceRegiesterDialog.bind(this)}
          onDelete={onClickDeleteSection}
          isActive
          {...props}
        />
      );
    } else {
      return <div></div>;
    }
  };

  const refreshOnUpdate = async () => {
    await RafClearDialogContent(preferenceListDialogRef);
    setState({
      showUpdatePreferenceContent: false,
      preferenceRow: null,
      preferenceDivKey: Math.random(),
    });
  };

  const closeUpdatePreferenceRegiesterDialog = async () => {
    await RafClearDialogContent(preferenceListDialogRef);
    setState({ showUpdatePreferenceContent: false, preferenceRow: null });
  };
  //UpdatePreferenceRegiester end

  //delete item start
  const onClickDeleteSection = () => {
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: deleteRecord.bind(this) },
      title: `Delete ${CareEsioEntity.CareRecipientPreference.DisplayName}`,
      position: { X: "center", Y: "center" },
      cssClass: `alert-dialog deleteDialog`,
    });
  };

  async function deleteRecord() {
    let { preferenceRow, preferenceEntity } = state;
    let progressDiv = showProgress(".deleteDialog.e-dialog");
    let isDeleted = await DeleteRecord(
      preferenceRow.UID,
      preferenceModule,
      preferenceEntity.UID
    );

    if (isDeleted) {
      hideProgress(progressDiv);
      deleteDialog.hide();
      refreshOnUpdate();
    } else {
      hideProgress(progressDiv);
      deleteDialog.hide();
      showWarningToast("Sorry something went wrong !");
    }
  }
  //delete item end

  function onChangeCareRecipient(fieldName: keyof any, e: any): void {
    (careRecipientRow[fieldName] as any) = e.value;
    let id = careRecipientRow.UID;
    const url = "DataList/Save";
    let saveRequestData = getSaveRequest(careRecipientRow, id);
    saveRequestData.EntityName = CareEsioEntity.CareRecipient.EntityName;
    repositoryActions
      .postDataAndGetResponse(
        url,
        saveRequestData,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (
            response.data.EntityId !== null &&
            response.data.EntityId !== undefined &&
            response.data.EntityId !== ""
          ) {
          } else if (
            response.data.Error !== null &&
            response.data.Error !== undefined &&
            response.data.Error !== ""
          ) {
            //console.log("Error on save", response.data.Error);
          }
        }
      })
      .catch((error) => error);
  }
  const getCareRecipientContent = () => {
    return (
      <div>
        {/* <RAFDetailFieldEditableCustom
            title="Who makes the decisions?"
            value={careRecipientRow.CareDetailsDecision}
            onChange={(e) => onChangeCareRecipient("CareDetailsDecision", e)}
            valueClassName="labelPrimartyText"
            type={RAFDataType.Multiline}
            name="CareDetailsDecision"
          ></RAFDetailFieldEditableCustom>
          <RAFDetailFieldEditableCustom
            title="What routines do you have?"
            value={careRecipientRow.CareDetailsRoutines}
            onChange={(e) => onChangeCareRecipient("CareDetailsRoutines", e)}
            valueClassName="labelPrimartyText"
            type={RAFDataType.Multiline}
            name="CareDetailsRoutines"
          ></RAFDetailFieldEditableCustom> */}
        {false && (
          <div>
            <CustomCardWidget
              cardClassName={"care_recipient_care_plan_item mb-3"}
            >
              <RAFPanel
                layout={RAFLayout.OneColumnLayout}
                className="gx-0 gy-2"
              >
                <div>
                  <div className="row w-100">
                    <div className="col-auto">
                      <span className="subtitle_1">What makes you happy?</span>
                    </div>
                    {state.isLoading === false && showAddBtn === true && (
                      <RAFPermissionRender
                        permissionName={
                          CareRecipientPreferencePermissionConstants.CareRecipientPreferenceAdd
                        }
                      >
                        <div className="col-auto ms-auto">
                          <RAFButtonComponent
                            className={"link-button text-decoration-underline"}
                            // className="custom-button-md"
                            action={RAFButtonConstant.Add}
                            // btnContent={`Add Task`}
                            onClick={() => onClickAddPreference("Happy")}
                            btnContent="Add"
                            showIcon={false}
                          />
                        </div>
                      </RAFPermissionRender>
                    )}
                  </div>
                  <RAFDetailFieldEditableCustom
                    //title="What makes you happy?"
                    value={
                      isNotNullAndUndefined(careRecipientRow)
                        ? careRecipientRow.CareDetailsHappy
                        : ""
                    }
                    onChange={(e) =>
                      onChangeCareRecipient("CareDetailsHappy", e)
                    }
                    valueClassName="labelPrimartyText"
                    type={RAFDataType.Multiline}
                    name="CareDetailsHappy"
                    showLabel={false}
                  ></RAFDetailFieldEditableCustom>
                </div>
                {/*<div className="custom__card__separator custom__card__separator__light"></div>*/}
                {preferenceListItems("Happy")}
              </RAFPanel>
            </CustomCardWidget>
            <CustomCardWidget
              cardClassName={"care_recipient_care_plan_item mb-3"}
            >
              <RAFPanel
                layout={RAFLayout.OneColumnLayout}
                className="gx-0 gy-2"
              >
                <div>
                  <div className="row w-100">
                    <div className="col-auto">
                      <span className="subtitle_1">What helps you relax?</span>
                    </div>
                    {state.isLoading === false && showAddBtn === true && (
                      <RAFPermissionRender
                        permissionName={
                          CareRecipientPreferencePermissionConstants.CareRecipientPreferenceAdd
                        }
                      >
                        <div className="col-auto ms-auto">
                          <RAFButtonComponent
                            className={"link-button text-decoration-underline"}
                            // className="custom-button-md"
                            action={RAFButtonConstant.Add}
                            // btnContent={`Add Task`}
                            onClick={() => onClickAddPreference("Relax")}
                            btnContent="Add"
                            showIcon={false}
                          />
                        </div>
                      </RAFPermissionRender>
                    )}
                  </div>
                  <RAFDetailFieldEditableCustom
                    //title="What helps you relax?"
                    value={careRecipientRow.CareDetailsRelax}
                    onChange={(e) =>
                      onChangeCareRecipient("CareDetailsRelax", e)
                    }
                    valueClassName="labelPrimartyText"
                    type={RAFDataType.Multiline}
                    name="CareDetailsRelax"
                  ></RAFDetailFieldEditableCustom>
                </div>
                {/*<div className="custom__card__separator custom__card__separator__light"></div>*/}
                {preferenceListItems("Relax")}
              </RAFPanel>
            </CustomCardWidget>
            <CustomCardWidget
              cardClassName={"care_recipient_care_plan_item mb-3"}
            >
              <RAFPanel
                layout={RAFLayout.OneColumnLayout}
                className="gx-0 gy-2"
              >
                <div>
                  <div className="row w-100">
                    <div className="col-auto">
                      <span className="subtitle_1">
                        What causes you stress?
                      </span>
                    </div>
                    {state.isLoading === false && showAddBtn === true && (
                      <RAFPermissionRender
                        permissionName={
                          CareRecipientPreferencePermissionConstants.CareRecipientPreferenceAdd
                        }
                      >
                        <div className="col-auto ms-auto">
                          <RAFButtonComponent
                            className={"link-button text-decoration-underline"}
                            // className="custom-button-md"
                            action={RAFButtonConstant.Add}
                            // btnContent={`Add Task`}
                            onClick={() => onClickAddPreference("Stress")}
                            btnContent="Add"
                            showIcon={false}
                          />
                        </div>
                      </RAFPermissionRender>
                    )}
                  </div>
                  <RAFDetailFieldEditableCustom
                    //title="What causes you stress?"
                    value={careRecipientRow.CareDetailsStress}
                    onChange={(e) =>
                      onChangeCareRecipient("CareDetailsStress", e)
                    }
                    valueClassName="labelPrimartyText"
                    type={RAFDataType.Multiline}
                    name="CareDetailsStress"
                  ></RAFDetailFieldEditableCustom>
                </div>
                {/*<div className="custom__card__separator custom__card__separator__light"></div>*/}
                {preferenceListItems("Stress")}
              </RAFPanel>
            </CustomCardWidget>
            <CustomCardWidget
              cardClassName={"care_recipient_care_plan_item mb-3"}
            >
              <RAFPanel
                layout={RAFLayout.OneColumnLayout}
                className="gx-0 gy-2"
              >
                <div>
                  <div className="row w-100">
                    <div className="col-auto">
                      <span className="subtitle_1">
                        What makes you frustrated and/or angry?
                      </span>
                    </div>
                    {state.isLoading === false && showAddBtn === true && (
                      <RAFPermissionRender
                        permissionName={
                          CareRecipientPreferencePermissionConstants.CareRecipientPreferenceAdd
                        }
                      >
                        <div className="col-auto ms-auto">
                          <RAFButtonComponent
                            className={"link-button text-decoration-underline"}
                            // className="custom-button-md"
                            action={RAFButtonConstant.Add}
                            // btnContent={`Add Task`}
                            onClick={() =>
                              onClickAddPreference("Frustrated / Angry")
                            }
                            btnContent="Add"
                            showIcon={false}
                          />
                        </div>
                      </RAFPermissionRender>
                    )}
                  </div>
                  <RAFDetailFieldEditableCustom
                    //title="What makes you frustrated and/or angry?"
                    value={careRecipientRow.CareDetailsAngry}
                    onChange={(e) =>
                      onChangeCareRecipient("CareDetailsAngry", e)
                    }
                    valueClassName="labelPrimartyText"
                    type={RAFDataType.Multiline}
                    name="CareDetailsAngry"
                  ></RAFDetailFieldEditableCustom>
                </div>
                {/*<div className="custom__card__separator custom__card__separator__light"></div>*/}
                {preferenceListItems("Frustrated / Angry")}
              </RAFPanel>
            </CustomCardWidget>
          </div>
        )}
        <div className="col-12">
          <UpdateCareRecipient
            careRecipientRow={careRecipientRow}
            objectUID={props.careRecipientUID}
            mode={"Preference"}
          />
        </div>

        {createPreferenceDlgDiv()}
      </div>
    );
  };

  if (isNotNullAndUndefined(props.careRecipientUID)) {
    return (
      <RAFPermissionRender
        permissionName={
          CareRecipientPreferencePermissionConstants.CareRecipientPreferenceRead
        }
      >
        {getPreferenceListContent()}
      </RAFPermissionRender>
    );
  } else {
    return <div></div>;
  }
}

export default React.memo(PreferenceList);
