import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  IsNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../../RAFComponents/models/Common/ListRequest";
import * as repositoryActions from "../../../../RAFComponents/store/actions/repositoryActions";
import { ContentType } from "../../../../constants/Common/Constants";
import { PriceListItemRow } from "./PriceListItemRow";
import { PriceListItemVariantRow } from "./PriceListItemVariant/PriceListItemVariantRow";

class PriceListItemVariantRequest {
  UID?: string;
  ServiceUID?: string;
}

export const getPriceListItemVariantList = async (
  serviceUID: string,
  pricelistUID: string
) => {
  return new Promise<PriceListItemVariantRow[]>(async (resolve) => {
    if (
      isNotNullAndUndefined(serviceUID) ||
      isNotNullAndUndefined(pricelistUID)
    ) {
      const priceListItemVariantRequest = new PriceListItemVariantRequest();
      if (isNotNullAndUndefined(pricelistUID)) {
        priceListItemVariantRequest.UID = pricelistUID;
      } else {
        priceListItemVariantRequest.ServiceUID = serviceUID;
      }
      repositoryActions
        .postDataAndGetResponse(
          "PriceListItem/VariantList",
          priceListItemVariantRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotEmptyArray(response.data.Entities)
          ) {
            resolve(response.data.Entities);
          } else {
            resolve(null);
          }
        })
        .catch((error) => resolve(null));
    } else {
      resolve(null);
    }
  });
};

export const getPriceListItemByPriceListItemId = (
  priceListItemUID: string,
  sortQuery?: string[]
) => {
  return new Promise<PriceListItemRow[]>((resolve) => {
    if (isNotNullAndUndefined(priceListItemUID)) {
      let customFilter: RAFCustomFilter = {};
      customFilter.Condition = "and";
      customFilter.Rules = [];

      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.CustomFilter = customFilter;
      listServiceRequest.Take = 1000;
      listServiceRequest.Skip = 0;

      if (isNotEmptyArray(sortQuery)) {
        listServiceRequest.Sort = sortQuery;
      }

      let additionalParams = [
        {
          key: "RelatedField",
          value: "ParentUID",
        },
        {
          key: "RelatedFieldValue",
          value: priceListItemUID,
        },
      ];

      if (
        isNotNullAndUndefined(additionalParams) &&
        additionalParams.length > 0
      ) {
        additionalParams.forEach((objProp) => {
          listServiceRequest[objProp.key] = objProp.value;
        });
      }

      if (isNotEmptyArray(sortQuery)) {
        listServiceRequest.Sort = sortQuery;
      }

      if (isNotNullAndUndefined(customFilter)) {
        listServiceRequest.CustomFilter = customFilter;
      }

      repositoryActions
        .postDataAndGetResponse(
          "PriceListItem/RelatedList",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (isNotNullAndUndefined(response)) {
            if (response.status === 204) {
              resolve(null);
            } else if (isNotNullAndUndefined(response.data)) {
              if (isNotNullAndUndefined(response.data.Entity)) {
                resolve(response.data.Entity);
              } else if (isNotEmptyArray(response.data.ResultTable)) {
                resolve(response.data.ResultTable);
              } else if (isNotNullAndUndefined(response.data.Entities)) {
                resolve(response.data.Entities);
              } else {
                resolve(null);
              }
            }
          } else {
            resolve(null);
          }
        });
    } else {
      resolve(null);
    }
  });
};

export const checkIsPriceListItemExist = (
  pricelistUID: string,
  serviceUID: string
) => {
  return new Promise<boolean>((resolve) => {
    if (
      isNotNullAndUndefined(pricelistUID) &&
      isNotNullAndUndefined(serviceUID)
    ) {
      const careTeamExistsParams = {
        PricelistUID: pricelistUID,
        ServiceUID: serviceUID,
      };

      repositoryActions
        .postDataAndGetResponse(
          "PriceListItem/Exists",
          careTeamExistsParams,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (isNotNullAndUndefined(response.data) && response.data === true) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
    } else {
      resolve(true);
    }
  });
};

export function removeSubstringsFromString(str: string): string {
  if (IsNullOrWhiteSpace(str)) {
    return null;
  }
  const substringsToRemove = [
    "- Weekday Daytime",
    "- Weekday Evening",
    "- Saturday",
    "- Sunday",
    "- Public Holiday",
    "- Weekday Night",
  ];

  let newStr = str;
  for (const substring of substringsToRemove) {
    const regex = new RegExp(substring, "g");
    newStr = newStr.replace(regex, "");
  }
  return newStr.trim();
}

export const RetrievePriceListItem = (id) => {
  return new Promise<PriceListItemRow>((resolve, reject) => {
    if (isNotNullAndUndefined(id)) {
      let url = "PriceListItem/Retrieve";
      return repositoryActions
        .postDataAndGetResponse(
          url,
          { EntityId: id },
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entity)
          ) {
            resolve(response.data.Entity);
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
};

// // Example usage:
// const inputArray: string[] = [
//     "Assistance With Self-Care Activities in a STA - Weekday Daytime",
//     "Assistance With Self-Care Activities in a STA - Weekday Evening",
//     "Assistance With Self-Care Activities in a STA - Saturday",
//     "Assistance With Self-Care Activities in a STA - Sunday",
//     "Assistance With Self-Care Activities in a STA - Public Holiday",
//     "Assistance With Self-Care Activities in a STA - Weekday Night",
//     "Assistance With Self-Care Activities - High Intensity - Weekday Daytime",
//     "Assistance With Self-Care Activities - High Intensity - Weekday Daytime - TTP",
//     "Assistance With Self-Care Activities - High Intensity - Weekday Evening",
//     "Assistance With Self-Care Activities - High Intensity - Weekday Evening - TTP",
//     "Assistance With Self-Care Activities - High Intensity - Saturday",
//     "Assistance With Self-Care Activities - High Intensity - Saturday - TTP",
//     "Assistance With Self-Care Activities - High Intensity - Sunday",
//     "Assistance With Self-Care Activities - High Intensity - Sunday - TTP",
//     "Assistance With Self-Care Activities - High Intensity - Public Holiday",
//     "Assistance With Self-Care Activities - High Intensity - Public Holiday - TTP",
//     "Assistance With Self-Care Activities - High Intensity - Weekday Night",
//     "Assistance With Self-Care Activities - High Intensity - Weekday Night - TTP"
// ];

// const resultArray: string[] = removeSubstringsFromArray(inputArray);
// console.log(resultArray);
