import { propertyOf } from '../../../RAFComponents/helpers/utils';
import { CareEsioEntity } from '../../../constants/CareESIO/CareEsioConstants';

export class ITimeChangeRequestRow {
    UID?: string;
    Category?: string;
    SystemFormUID?: string;
    SystemForm?: string;
    Reason?: string;
    Status?: string;
    Assignee?: string;
    AssigneeUID?: string;
    StartDate?: Date;
    EndDate?: Date;
    RelatedTo?: string;
    RelatedToUID?: string;
    RelatedToType?: string;
    SecondaryRelatedToUID?: string;
    SecondaryRelatedToType?: string;
    SecondaryRelatedTo?: string;
    TimeChangeRequestId?: string;
    FormLibraryUID?: string;
    FormLibrary?: string;
    Title?: string;
    SystemFormDataId?: string;
    TagsListJson?: string[];
    PortalUID?: string;
    Portal?: string;

    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    LastActivityDate?: Date;
}

export class TimeChangeRequestRow extends ITimeChangeRequestRow {
    getClassName?() {
        return CareEsioEntity.TimeChangeRequest.EntityName;
    }
    getIdField?(): string {
        return propertyOf<TimeChangeRequestRow>("UID");
    }
    getListUrl?(): string {
        return "TimeChangeRequest/List";
    }
    getSaveUrl?(): string {
        return "TimeChangeRequest/Save";
    }
    getRelatedListUrl?(): string {//use route name(entity displayname)
        return "TimeChangeRequest/RelatedList";
    }

}
