import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer
} from "react";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFCollapse from "../../../RAFComponents/Navigation/RAFCollapse";
import RAFCollapseWithPlusIcon from "../../../RAFComponents/Navigation/RAFCollapseWithPlusIcon";
import RAFEmptyState from "../../../RAFComponents/Navigation/RAFEmptyState";
import {
  getAllAttributes,
  getFields,
  getRelatedRecords
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import { RAFEventName, subscribeRAFEvent, triggerRAFEvent, unsubscribeRAFEvent } from "../../../RAFComponents/helpers/RAFDocumentEvents";
import {
  ConvertSystemName,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { getEntityByObjectName } from "../../../RAFMaster/helpers/RMutils";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareRecipientTriggerPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  RAFButtonConstant
} from "../../../constants/Common/Constants";
import RAFCreate from "../../Common/Create";
import { RAFObjectContext } from "../../Common/Providers/RAFObjectContextProvider";
import TriggerContent from "./TriggerContent";
//const RAFGrid4 = React.lazy(() => import('../../../RAFComponents/Grid/RAFGrid4'));

interface IProps {
  careRecipientUID: string;
  uiStyle?: "CardStyle" | "DetailsStyle" | "ProfileSummaryStyle" | "SummaryStyle" | "SummaryDetails";
  showAddBtn?: boolean;
  isCollapsed?: boolean;
  paddingClass?: string;
}

interface IState {
  isLoading: boolean;
  triggerItems: any;
  triggerEntity: EntityRow;
  showCreateTriggerContent: boolean;
}

function TriggerList({
  showAddBtn = true,
  uiStyle = "CardStyle",
  paddingClass = "p-2-5 p-md-3",
  isCollapsed = false,
  ...props
}: PropsWithChildren<IProps>) {
  const triggerModule = CareEsioEntity.CareRecipientTrigger.EntityName;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      triggerItems: null,
      triggerEntity: null,
      showCreateTriggerContent: false,
    }
  );

  useEffect(() => {
    refresh();
  }, [props.careRecipientUID]);

  useEffect(() => {
    const relatedModule = isNotNullAndUndefined(props.careRecipientUID)
      ? `${CareEsioEntity.CarePlan.EntityName}_${triggerModule}`
      : triggerModule;

    const eventKey = `${RAFEventName.GridLoad}_${relatedModule}_${props.careRecipientUID}`;
    subscribeRAFEvent(
      eventKey,
      reLoadListContent
    );

    return () => {
      unsubscribeRAFEvent(
        eventKey,
        reLoadListContent
      );
    };
  });

  const reLoadListContent = (args) => {
    if (isNotNullAndUndefined(args) && isNotNullAndUndefined(args.detail)) {
      const argsUIStyle = args.detail.uiStyle;
      if (argsUIStyle === uiStyle) {
        refresh();
      }
    };
  };

  const refresh = async () => {
    if (isNotNullAndUndefined(props.careRecipientUID)) {
      setState({
        isLoading: true,
        triggerEntity: null,
        showCreateTriggerContent: false,
      });
      const [triggerEntity, attributeRelatedList, queryAttributes] = await Promise.all([
        getEntityByObjectName({
          ObjectName: ConvertSystemName(triggerModule),
        }),
        getAllAttributes(ConvertSystemName(triggerModule)),
        getFields(ConvertSystemName(triggerModule)),
      ]);//dont remove this line  details filed is used in loop below this line add to avoid multiple calls

      const triggerItems = await getRelatedRecords(
        triggerModule,
        null,
        "CareRecipientUID",
        props.careRecipientUID,
        triggerEntity.UID
      );

      setState({ isLoading: false, triggerItems, triggerEntity });
      // setState({ isLoading: false, triggerEntity });
    }
  };

  const createTriggerDlgDiv = () => {
    if (state.showCreateTriggerContent) {
      return (
        <DialogComponent
          header={`Add ${CareEsioEntity.CareRecipientTrigger.DisplayName}`}
          showCloseIcon
          visible={state.showCreateTriggerContent}
          cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
          id={`dlg_create_${triggerModule}`}
          content={createTriggerContent.bind(this)}
          isModal
          target="body"
          closeOnEscape={false}
          close={closeCreateTriggerDialog.bind(this)}
          zIndex={1200}
          open={PreventFocusOnDialogOpen}
        />
      );
    } else {
      return null;
    }
  };

  const headerContent = () => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <div className="header_5">
          {CareEsioEntity.CareRecipientTrigger.CollectionName}
        </div>
        <div>
          {state.isLoading === false && showAddBtn === true && (
            <div className="col-12 d-flex justify-content-center">
              <RAFPermissionRender
                permissionName={
                  CareRecipientTriggerPermissionConstants.CareRecipientTriggerAdd
                }
              >
                <RAFButtonComponent
                  action={RAFButtonConstant.Add}
                  onClick={() => createTriggerDialogOpen()}
                  className="btn_brand_primary semi_dark"
                />
              </RAFPermissionRender>
              {createTriggerDlgDiv()}
            </div>
          )}
        </div>
      </div>
    );
  };

  const getTriggerListContent = () => {
    const behaviourCount = isNotEmptyArray(state.triggerItems)
      ? state.triggerItems.length
      : 0;
    if (uiStyle === "DetailsStyle") {
      return (
        <div className="row g-3">
          <div className="col-12">
            {headerContent()}
          </div>
          {triggerListItem()}
        </div>
      );
    }
    else if (uiStyle === "ProfileSummaryStyle") {
      return (
        <div className="" key={behaviourCount}>
          <div className="row gx-0 gy-2">
            <div className="col-12">
              <div className="d-flex align-items-center">
                <div className="profile_card_details_label">
                  {'Behaviours'}
                </div>
                <div className={`ms-2 raf-badge-circle raf-badge${behaviourCount > 0 ? '-tertiary-circle' : '-neutral-circle'}`}>
                  {behaviourCount}
                </div>
              </div>
            </div>
            {behaviourCount > 0 ?
              <div className="col-12">
                {triggerListItemSummary()}

              </div>
              :
              ''
            }
          </div>
        </div>
      );
    }
    else if (uiStyle === "SummaryStyle") {
      return (
        <CustomCardWidget cardContentClassName="p-0" key={behaviourCount}>
          <RAFCollapseWithPlusIcon
            toggleArrowIcon="Arrow"
            title="Behaviours"
            collapsePanelHeaderClass={"collapsePanel__header__fixedHeight__lg"}
            badge={{
              count: behaviourCount,
              show: true,
              className: "ms-2 raf-badge-circle raf-badge-tertiary-circle",
            }}
            isCollapsed={behaviourCount > 0 ? false : true}
            collapsible={
              behaviourCount > 0 ? true : false
            }
            collapsePanelContentClassname={paddingClass}
          >
            {triggerListItemSummary()}
          </RAFCollapseWithPlusIcon>
        </CustomCardWidget>
      );
    } else if (uiStyle === "SummaryDetails") {
      return (
        <CustomCardWidget cardContentClassName="p-0">
          <RAFCollapseWithPlusIcon
            toggleArrowIcon="Arrow"
            title="Behaviours"
            collapsePanelHeaderClass={"collapsePanel__header__fixedHeight__lg"}
            // collapsePanelHeaderClass={"px-0"}
            allowFullRowClick
            collapsePanelContentClassname="p-0"
            badge={{
              count: behaviourCount,
              show: true,
              className: "ms-2 raf-badge-circle raf-badge-tertiary-circle",
            }}
            isCollapsed={isCollapsed}
            collapsible={true}
            showSeparator={true}
            customButton={
              <div>
                {showAddBtn !== false && (
                  <div className="d-flex align-items-baseline">
                    <div className="col-auto">
                      <RAFPermissionRender
                        permissionName={
                          CareRecipientTriggerPermissionConstants.CareRecipientTriggerAdd
                        }
                      >
                        <RAFButtonComponent
                          // className={`custom-button-sm primary-custom-button${!BrowserIsDevice ? " min-width-lg" : ""}`}
                          className={`custom-button-sm primary-custom-button`}
                          // className="custom-button-md"
                          action={RAFButtonConstant.Add}
                          // {...!BrowserIsDevice ?
                          //   { btnContent: `Add ${CareEsioEntity.CareRecipientTrigger.DisplayName}` }
                          //   :
                          //   {}
                          // }
                          onClick={() => createTriggerDialogOpen()}
                        />
                      </RAFPermissionRender>
                    </div>
                  </div>
                )}
              </div>
            }
          >
            <div>
              {createTriggerDlgDiv()}
              {triggerListItem()}
            </div>
          </RAFCollapseWithPlusIcon>
        </CustomCardWidget>
      );
    } else {
      return (
        <div className="col-12">
          <div className="custom__card overflow-hidden bg-light-blue-3">
            <div className="custom__card__content p-0">
              <RAFCollapse
                title={CareEsioEntity.CareRecipientTrigger.CollectionName}
                headerRowClassName="with-height"
                // headerRowClassName="with-height bg-card-header-8"
                contentCardClassName="relatedListOuterDiv"
                headerBadge={
                  isNotEmptyArray(state.triggerItems) && (
                    <div className="secondary-text">
                      <span className="e-badge e-badge-grey-border e-badge-circle e-badge-md e-badge-notification ms-2 position-static">
                        {state.triggerItems.length}
                      </span>
                    </div>
                  )
                }
                removeContentCardPadding
                IsCollapsed={true}
                customButton={
                  <div>
                    {showAddBtn !== false && (
                      <div className="d-flex align-items-baseline">
                        <div className="col-auto">
                          <RAFPermissionRender
                            permissionName={
                              CareRecipientTriggerPermissionConstants.CareRecipientTriggerAdd
                            }
                          >
                            <RAFButtonComponent
                              // className={`custom-button-sm primary-custom-button${!BrowserIsDevice ? " min-width-lg" : ""}`}
                              className={`custom-button-sm primary-custom-button`}
                              // className="custom-button-md"
                              action={RAFButtonConstant.Add}
                              // {...!BrowserIsDevice ?
                              //   { btnContent: `Add ${CareEsioEntity.CareRecipientTrigger.DisplayName}` }
                              //   :
                              //   {}
                              // }
                              onClick={() => createTriggerDialogOpen()}
                            />
                          </RAFPermissionRender>
                        </div>
                      </div>
                    )}
                  </div>
                }
              // Collapsible={showExpandBtn}
              >
                {createTriggerDlgDiv()}
                {triggerListItem()}
              </RAFCollapse>
            </div>
          </div>
        </div>
      );
    }
  };

  const triggerListItemSummary = () => {
    if (isNotEmptyArray(state.triggerItems)) {
      return (
        <div className="row g-2">
          {state.triggerItems.map((item) => {
            return (
              <div className={"col-auto"} key={item.UID}>
                <div className="raf_badge surface_neutral_base">
                  <span className="body_2_dark ecllipseSecondLine">
                    {item.Title}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  const triggerListItem = () => {
    if (state.isLoading === false) {
      if (isNotEmptyArray(state.triggerItems)) {
        return (
          <div className="col-12">
            <div className={`custom__card__content ${paddingClass}`}>
              <div className="row g-0 gy-2 gy-md-3">
                {state.triggerItems.map((item) => {
                  return (
                    <TriggerContent
                      key={item.UID}
                      triggerRow={item}
                      triggerEntity={state.triggerEntity}
                      onSave={onSaved}
                      allowEdit={uiStyle === "SummaryStyle" ? false : true}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="col-12">
            <RAFEmptyState
              title={`No ${CareEsioEntity.CareRecipientTrigger.DisplayName} recorded.`}
              {...(showAddBtn === true && {
                body: `To get started, please add a new ${CareEsioEntity.CareRecipientTrigger.DisplayName} by clicking on the "Add" button.`,
              })}
            />
          </div>
        );
      }
    } else {
      return (
        <div className="col-12">
          <div className="custom__card__content p-0">
            <div className="row g-3">
              <div className="container-fluid px-0">
                <ACLoadingPanel loadingText="Preparing Data..." />
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  //create start

  //addTrigger start
  const createTriggerDialogOpen = () => {
    setState({ showCreateTriggerContent: true });
  };

  const createTriggerContent = () => {
    if (state.showCreateTriggerContent) {
      let initialValues = {};

      const objectContext = React.useContext(RAFObjectContext);
      const rafObject = isNotNullAndUndefined(objectContext)
        ? objectContext.rafObject
        : null;
      if (isNotNullAndUndefined(rafObject)) {
        initialValues["CareRecipientUID"] = rafObject.UID;
        initialValues["CareRecipient"] = rafObject.first_name;
      }

      return (
        <RAFCreate
          initialValues={initialValues}
          onSave={onSaved.bind(this)}
          onClose={closeCreateTriggerDialog.bind(this)}
          moduleName={triggerModule}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  };

  const onSaved = () => {
    refresh();
    const relatedModule = isNotNullAndUndefined(props.careRecipientUID)
      ? `${CareEsioEntity.CarePlan.EntityName}_${triggerModule}`
      : triggerModule;

    const eventKey = `${RAFEventName.GridLoad}_${relatedModule}_${props.careRecipientUID}`;
    triggerRAFEvent(eventKey, {
      uiStyle: `ProfileSummaryStyle`,
    });
  };

  const closeCreateTriggerDialog = async () => {
    setState({ showCreateTriggerContent: false });
  };
  //addTrigger end

  if (isNotNullAndUndefined(props.careRecipientUID)) {
    return (
      <RAFPermissionRender
        permissionName={
          CareRecipientTriggerPermissionConstants.CareRecipientTriggerRead
        }
      >
        {getTriggerListContent()}
      </RAFPermissionRender>
    );
  } else {
    return <div></div>;
  }
}

export default React.memo(TriggerList);
