import { DialogUtility } from "@syncfusion/ej2-popups";
import React, {
    PropsWithChildren,
    Reducer,
    useEffect,
    useReducer,
} from "react";
import { FormRenderProps } from "react-final-form";
import RAFCheckBox from "../../../RAFComponents/Inputs/RAFCheckBox";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import RAFLookUpMUI from "../../../RAFComponents/Inputs/RAFLookUpMUI";
import RAFRRuleInput from "../../../RAFComponents/Inputs/RAFRRuleInput";
import { setFormValue } from "../../../RAFComponents/Inputs/RFFUtils";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFAttributeRelatedListProvider from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { RAFCustomFilter, RAFCustomOperator } from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
    DeleteRecord,
    RetrieveRecord,
    getRelatedRecords,
    hideProgress,
    showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import {
    IDialogProps,
    isNotEmptyArray,
    isNotNullAndUndefined,
    propertyOf,
} from "../../../RAFComponents/helpers/utils";
import { FormLibraryRow } from "../../../RAFComponents/models/Common/FormLibraryRow";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
    RAFButtonConstant,
    RAFLayout,
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import RAFEntityProvider from "../../Common/Providers/RAFEntityProvider";
import { saveRecurringConfigAPI } from "./RecurringConfigHelper";
import { RecurringConfigRow } from "./RecurringConfigRow";

const checkIsItemExist = (formValue: RecurringConfigRow) => {
    return new Promise<boolean>(async (resolve) => {
        if (isNotNullAndUndefined(formValue) && isNotNullAndUndefined(formValue.FormUID) && isNotNullAndUndefined(formValue.RelatedToUID)) {
            let customFilter: RAFCustomFilter = {};
            customFilter.Condition = "and";
            customFilter.Rules = [];

            let filter1: RAFCustomFilter = {};
            let filterVal1: string[] = [];
            filterVal1.push(formValue.FormUID);
            filter1.Operator = RAFCustomOperator.Equal;
            filter1.Value = filterVal1;
            filter1.Field = propertyOf<RecurringConfigRow>('FormUID');
            customFilter.Rules.push(filter1);

            const recurringConfigItems: RecurringConfigRow[] =
                await getRelatedRecords(
                    CareEsioEntity.RecurringConfig.EntityName,
                    null,
                    propertyOf<RecurringConfigRow>('RelatedToUID'),
                    formValue.RelatedToUID,
                    null,
                    null,
                    null,
                    null,
                    customFilter
                );
            if (isNotEmptyArray(recurringConfigItems)) {
                if (isNotNullAndUndefined(formValue.UID)) {
                    const isCurrentItem = recurringConfigItems.find(x => x.UID === formValue.UID);
                    if (isNotNullAndUndefined(isCurrentItem) && isNotNullAndUndefined(isCurrentItem.UID)) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }

                } else {
                    resolve(true);
                }
            } else {
                resolve(false);
            }
        } else {
            resolve(false);
        }
    });
};

interface IProps {
    relatedToUID?: string;
    relatedTo?: string;
    relatedToType?: string;
    initialValues?: RecurringConfigRow;

    onDelete?: () => void;
    objectUID?: string;
}

interface IState {
    isLoading: boolean;
    noContent: boolean;
    recurringConfigRow: RecurringConfigRow;
}

function ManageRecurringConfig({
    ...props
}: PropsWithChildren<IProps & IDialogProps>) {
    let rafForm: FormRenderProps | null;

    const moduleName = CareEsioEntity.RecurringConfig.EntityName;

    const outerDivId = `manageupdate_recurringConfig_dialog`;
    let deleteDialog: any;

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            isLoading: true,
            noContent: true,
            recurringConfigRow: null,
        }
    );

    useEffect(() => {
        refresh();
    }, []);

    const refresh = async () => {
        if (props.isActive) {
            setState({ isLoading: true });
            const [recurringConfigRow] = await Promise.all([getIntitailRecurringConfigRow()]);

            if (isNotNullAndUndefined(recurringConfigRow)) {
                setState({ isLoading: false, noContent: false, recurringConfigRow });
            } else {
                setState({ isLoading: false, noContent: true });
            }
        }
    };

    const getIntitailRecurringConfigRow = () => {
        return new Promise<RecurringConfigRow>(async (resolve) => {
            if (isNotNullAndUndefined(props.objectUID)) {
                const recurringConfigRow = await RetrieveRecord(props.objectUID, moduleName);
                if (
                    isNotNullAndUndefined(recurringConfigRow) &&
                    isNotNullAndUndefined(recurringConfigRow.UID)
                ) {
                    resolve(recurringConfigRow);
                } else {
                    resolve(null);
                }
            } else {
                if (isNotNullAndUndefined(props.initialValues)) {
                    let initialObject = props.initialValues;

                    resolve(initialObject);
                } else {
                    let initialObject = new RecurringConfigRow();
                    if (isNotNullAndUndefined(props.relatedToUID) && isNotNullAndUndefined(props.relatedTo) && isNotNullAndUndefined(props.relatedToType)) {
                        initialObject.RelatedToUID = props.relatedToUID;
                        initialObject.RelatedTo = props.relatedTo;
                        initialObject.RelatedToType = props.relatedToType;
                    }
                    initialObject.IsRequired = true;
                    resolve(initialObject);
                }
            }
        });
    };

    const onSubmitRecurringConfig = async (value: RecurringConfigRow) => {
        let progressDiv = showProgress(`#${outerDivId}`);

        const isItemExist = await checkIsItemExist(value);

        if (isItemExist) {
            hideProgress(progressDiv);
            showWarningToast("Item already exist with same form name");
            return;
        }

        const response = await saveRecurringConfigAPI(value, moduleName);
        hideProgress(progressDiv);
        if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.entityId)
        ) {
            if (isNotNullAndUndefined(props.onSave)) {
                props.onSave(response.entityId);
            }
        } else {
            showWarningToast("Sorry something went wrong !");
        }
    };

    //delete item start
    const onClickDelete = () => {
        deleteDialog = DialogUtility.confirm({
            animationSettings: { effect: "Fade" },
            cancelButton: { text: "No" },
            closeOnEscape: false,
            content: "Are you sure want to delete?",
            okButton: { text: "Yes", click: deleteRecord.bind(this) },
            title: `Delete ${CareEsioEntity.RecurringConfig.DisplayName}`,
            position: { X: "center", Y: "center" },
            cssClass: `alert-dialog deleteDialog`,
        });
    };

    async function deleteRecord() {
        let progressDiv = showProgress(".deleteDialog.e-dialog");

        let isDeleted = await DeleteRecord(props.objectUID, moduleName);

        if (isDeleted) {
            hideProgress(progressDiv);
            deleteDialog.hide();
            if (isNotNullAndUndefined(props.onDelete)) {
                props.onDelete();
            } else if (isNotNullAndUndefined(props.onSave)) {
                props.onSave();
            }
        } else {
            hideProgress(progressDiv);
            deleteDialog.hide();
            showWarningToast("Sorry something went wrong !");
        }
    }
    //delete item end

    const getFormLookUpContent = () => {
        let customFilter: RAFCustomFilter = {};
        customFilter.Condition = "and";
        customFilter.Rules = [];
        let filter: RAFCustomFilter = {};
        let filterVal: string[] = [];
        filterVal.push(CareEsioEntity.CareShiftLog.DisplayName);
        filter.Operator = RAFCustomOperator.Equal;
        filter.Value = filterVal;
        filter.Field = propertyOf<FormLibraryRow>('CategoryType');
        customFilter.Rules.push(filter);
        return (
            <RAFLookUpMUI
                field={propertyOf<RecurringConfigRow>("Form")}
                label="Form"
                url="FormLibrary/LookUp"
                moduleName={RAFEntityName.FormLibrary}
                onChanged={onChangeFormLibraryLookUp}
                customFilter={customFilter}
                required
                formGroupClassName="mb-0"
            />
        );
    };

    const getIsRequiredContent = () => {
        return (
            <RAFCheckBox
                field={propertyOf<RecurringConfigRow>("IsRequired")}
                label="Required?"
                showLabel={true}
                uitype={"switch"}
                labelPosition="right"
                labelClassName="col"
                inputFieldClassName="col-auto d-flex align-items-center"
                formGroupClassName="mb-0"
            />
        );
    };

    const getFrequencyContent = () => {
        return (
            <div className="col-12">
                <RAFRRuleInput
                    field={propertyOf<RecurringConfigRow>("RruleExpression")}
                    rRuleExpressionPropertyName={propertyOf<RecurringConfigRow>("RruleExpression")}
                    label={'Frequency'}
                    formGroupClassName="mb-0"
                />
            </div>
        );
    };

    const onChangeFormLibraryLookUp = (label) => {
        setFormValue(rafForm, propertyOf<RecurringConfigRow>("EntityDisplayName"), label);
    };

    if (props.isActive) {
        if (state.isLoading === false) {
            if (state.noContent === false) {
                return (
                    <RAFEntityProvider moduleName={moduleName}>
                        <RAFAttributeRelatedListProvider moduleName={moduleName}>
                            <RAFForm
                                initialValues={state.recurringConfigRow}
                                formRef={(g) => (rafForm = g)}
                                layout={RAFLayout.TwoColumnLayout}
                                onSubmit={onSubmitRecurringConfig}
                                className="h-100"
                            >
                                <div className="e-dlg-content-outer" id={outerDivId}>
                                    <div className="e-dlg-body-content">
                                        <div className="row gx-2 gy-4">
                                            {getFormLookUpContent()}
                                            {getFrequencyContent()}
                                            {getIsRequiredContent()}
                                        </div>
                                    </div>
                                    <div className="e-dlg-footerContent ">
                                        <div className="w-100">
                                            <div className="row gx-2">
                                                {isNotNullAndUndefined(props.objectUID) && (
                                                    <div className="col-auto">
                                                        <RAFButtonComponent
                                                            action={RAFButtonConstant.Delete}
                                                            onClick={() => onClickDelete()}
                                                            idString="DeleteContent"
                                                            className="e-danger e-outline"
                                                        />
                                                    </div>
                                                )}
                                                <div className="col-auto ms-auto">
                                                    <RAFButtonComponent
                                                        type="button"
                                                        isPrimary
                                                        action={RAFButtonConstant.Save}
                                                        onClick={() => rafForm && rafForm.form.submit()}
                                                        idString="CreateContent"
                                                        disabled={rafForm && rafForm.submitting}
                                                    />
                                                </div>
                                                <div className="col-auto">
                                                    <RAFButtonComponent
                                                        type="button"
                                                        action={RAFButtonConstant.Cancel}
                                                        onClick={props.onClose}
                                                        idString="CreateContent"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </RAFForm>
                        </RAFAttributeRelatedListProvider>
                    </RAFEntityProvider>
                );
            } else {
                return (
                    <div className="container-fluid px-0">
                        <RAFDeletedRecordState title="This item is either deleted or You do not have sufficient privileges to view this item." />
                    </div>
                );
            }
        } else {
            return (
                <div className="container-fluid px-0">
                    <ACLoadingPanel loadingText="Loading..." />
                </div>
            );
        }
    } else {
        return <div></div>;
    }
}

export default React.memo(ManageRecurringConfig);