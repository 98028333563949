
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import { ContentType } from "../../../constants/Common/Constants";
import { isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import { RAFCustomFilter, RAFCustomOperator } from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";

export const getAllPriceList = (status: string) => {
    let url = `DataList/List`;
    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;
    listServiceRequest.EntityName = CareEsioEntity.CarePriceList.EntityName;

    if (isNotNullAndUndefined(status)) {
        let customFilter: RAFCustomFilter = {};
        customFilter.Condition = "and";
        customFilter.Rules = [];

        let filter: RAFCustomFilter = {};
        let filterVal: string[] = [];
        filterVal.push(status);
        filter.Operator = RAFCustomOperator.Equal;
        filter.Value = filterVal;
        filter.Field = "Status";
        customFilter.Rules.push(filter);

        listServiceRequest.CustomFilter = customFilter;
    }

    return new Promise<any[]>((resolve) => {
        return repositoryActions
            .postDataAndGetResponse(
                url,
                listServiceRequest,
                null,
                ContentType.applicationJson,
                false
            )
            .then((response) => {
                if (isNotNullAndUndefined(response)) {
                    if (response.status === 204) {
                        resolve(null);
                    } else if (
                        isNotNullAndUndefined(response.data) &&
                        isNotNullAndUndefined(response.data.Entity)
                    ) {
                        resolve(response.data.Entity);
                    } else if (
                        response.data &&
                        response.data.ResultTable &&
                        response.data.ResultTable.length > 0
                    ) {
                        resolve(response.data.ResultTable);
                    } else {
                        resolve(null);
                    }
                } else {
                    resolve(null);
                }
            });
    });
};
