import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
} from "react";
import { Field, FormRenderProps } from "react-final-form";
import RAFAutoCompleteMUI from "../../../RAFComponents/Inputs/RAFAutoCompleteMUI";
import RAFChoiceOption from "../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFDropdown from "../../../RAFComponents/Inputs/RAFDropdown";
import RAFDropdownCC from "../../../RAFComponents/Inputs/RAFDropdownCC";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import RAFHtmlEditor from "../../../RAFComponents/Inputs/RAFHtmlEditor";
import RAFTextArea from "../../../RAFComponents/Inputs/RAFTextArea";
import RAFTextBox from "../../../RAFComponents/Inputs/RAFTextBox";
import {
  getFormValue,
  setFormValue,
} from "../../../RAFComponents/Inputs/RFFUtils";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDetailFieldCustom from "../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  getSessionStorage,
  setSessionStorage,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  IsNotNullOrWhiteSpace,
  IsNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined
} from "../../../RAFComponents/helpers/utils";
import { RAFViewRow } from "../../../RAFComponents/models/Common/RAFViewRow";
import classicFormImg from "../../../assets/CareESIO/ClassicForm.png";
import cardFormImg from "../../../assets/CareESIO/cardForm.svg";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { RiskRegisterPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  RAFButtonConstant,
  RAFLayout,
  RAFRiskRegisterType,
  StorageKey,
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { RAFBPCurrentStatus } from "../../../constants/Common/RMConstants";
import RAFCreate from "../../Common/Create";
import RAFEntityProvider from "../../Common/Providers/RAFEntityProvider";
import RAFObjectContextProvider, {
  RAFObjectContext,
} from "../../Common/Providers/RAFObjectContextProvider";
import { getAssessmentListByCarePlanAndRecipientUID } from "../CareAssessmentLibrary/CareAssessmentHelper";
import CareCategoryAndSubCategory from "../CareCategoryAndSubCategory";

interface IProps {
  isActive: boolean;
  moduleName: string;
  onSave: (entityId?: string, objectName?: string) => void;
  onClose: () => void;
  initialValues?: any;
  riskType?: RAFRiskRegisterType.Risk | RAFRiskRegisterType.HealthCondition;
}

interface IState {
  isLoading: boolean;
  initialValues: any;
  showBackBtn: boolean;
  selectedItemIndex: number;
  careAssessments: any[];
}

function CreateRisk({ ...props }: PropsWithChildren<IProps>) {
  let rafForm: FormRenderProps | null;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      showBackBtn: false,
      initialValues: null,
      selectedItemIndex: 0,
      careAssessments: [],
    }
  );

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    if (props.isActive) {
      let initialObject = {};
      if (isNotNullAndUndefined(props.initialValues)) {
        initialObject = props.initialValues;
      }

      // const selectedItemIndex = isNotNullAndUndefined(
      //   initialObject["CategoryUID"]
      // )
      //   ? 1
      //   : props.riskType === RAFRiskRegisterType.HealthCondition ||
      //     props.riskType === RAFRiskRegisterType.Risk
      //   ? 2
      //   : 0;

      //const showBackBtn = selectedItemIndex === 1 ? false : true;

      const careAssessments = await getAssessmentListByCarePlanAndRecipientUID(
        initialObject["CarePlanUID"],
        initialObject["CareRecipientUID"],
        CareEsioEntity.CareAssessment.EntityName,
        IsNullOrWhiteSpace(initialObject["AssessmentUID"]) ? RAFBPCurrentStatus.Draft : null
      );
      setState({
        initialValues: initialObject,
        selectedItemIndex: 2,
        //showBackBtn,
        isLoading: false,
        careAssessments,
      });
    }
  };

  const onSubmitObject = (
    values,
    saveObject?: (
      objectData?: any
    ) => Promise<{ objectUID?: string; objectName?: string; }>
  ) => {
    saveObject(values)
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.objectUID)
        ) {
          showSuccessToast("Saved successfully");

          let allViews: RAFViewRow[] = JSON.parse(
            getSessionStorage(
              StorageKey.allViews_modulename + props.moduleName,
              true
            )
          );
          let recentlyCreatedView: RAFViewRow =
            allViews && allViews.find((x) => x.ViewName === "Recently_Created"); //recently_created;
          if (isNotNullAndUndefined(recentlyCreatedView)) {
            setSessionStorage(
              StorageKey.recentViewId_modulename + props.moduleName,
              true,
              JSON.stringify({
                viewId: recentlyCreatedView.UID,
                viewName: recentlyCreatedView.DisplayName,
              })
            );
          }
          if (props.onSave)
            props.onSave(response.objectUID, response.objectName);
        } else {
          showWarningToast("Sorry something went wrong !");
        }
      })
      .catch((error) => error);
  };

  const onSelectGeneralRisk = () => {
    setFormValue(rafForm, "CategoryUID", null);
    setFormValue(rafForm, "Category", null);
    setFormValue(rafForm, "SubCategoryUID", null);
    setFormValue(rafForm, "SubCategory", null);
    setFormValue(rafForm, "Icon", null);
    setState({ selectedItemIndex: 2, showBackBtn: true });
  };

  const onSelectContentBtn = (action: "Previous" | "Next") => {
    if (action === "Previous") {
      const categoryUID = getFormValue(rafForm, "CategoryUID");
      if (state.selectedItemIndex === 2 && IsNullOrWhiteSpace(categoryUID)) {
        setState({ selectedItemIndex: 0, showBackBtn: false });
      } else {
        let selectedItemIndex = state.selectedItemIndex - 1;
        const showBackBtn = selectedItemIndex !== 0 ? true : false;
        setState({ selectedItemIndex, showBackBtn });
      }
    } else if (action === "Next") {
      let selectedItemIndex = state.selectedItemIndex + 1;
      const showBackBtn = selectedItemIndex !== 0 ? true : false;
      setState({ selectedItemIndex, showBackBtn });
    }
  };

  const getFormFields = () => {
    let { riskType } = props;
    if (riskType === RAFRiskRegisterType.HealthCondition) {
      return (
        <>
          <div className="col-md-12">{getTypeActivityDropdown()}</div>
          <div className="col-md-12">
            <RAFDropdownCC
              field="RiskLevel"
              label="Severity"
              isColorOption
              uitype="colorpicker"
              moduleName={CareEsioEntity.RiskRegister.EntityName}
              displayDropdownType="customDropdownBtnForMinCount"
              required
              formGroupClassName="m-0"
            />
          </div>
          <div className="col-md-12">
            <RAFTextArea
              field="Mitigation"
              showLabel
              label="Provide a brief description or observations"
              placeholder="Description"
              rows={3}
              formGroupClassName="m-0"
            />
          </div>
        </>
      );
    } else if (riskType === RAFRiskRegisterType.Risk) {
      return (
        <>
          <div className="col-md-12">{getTypeActivityDropdown()}</div>
          <div className="col-md-12">
            <RAFDropdownCC
              field="RiskLevel"
              label="Risk Level"
              isColorOption
              displayDropdownType="customDropdownBtnForMinCount"
              uitype="colorpicker"
              moduleName={CareEsioEntity.RiskRegister.EntityName}
              required
              formGroupClassName="m-0"
            />
          </div>
          <div className="col-md-12">
            <RAFDropdownCC
              field="Likelihood"
              label="Likelihood"
              isColorOption
              displayDropdownType="customDropdownBtnForMinCount"
              uitype="colorpicker"
              moduleName={CareEsioEntity.RiskRegister.EntityName}
              //required
              formGroupClassName="m-0"
            />
          </div>
          {getSupportNeedsDropdown()}
          <div className="col-md-12">
            <RAFHtmlEditor
              field="Mitigation"
              label="Mitigation"
              rows={5}
              fullHeight={false}
              //required
              formGroupClassName="m-0"
            />
          </div>
        </>
      );
    }
  };

  const onSupportNeedSelected = (label, value) => {
    if (isNotNullAndUndefined(value)) {
      let { careAssessments } = state;
      let selectedAssessment = careAssessments.find((x) => x.UID === value);
      if (isNotNullAndUndefined(selectedAssessment)) {
        setFormValue(rafForm, "Assessment", selectedAssessment["title"]);
        setFormValue(rafForm, "AssessmentUID", selectedAssessment.UID);
        setFormValue(
          rafForm,
          "AssessmentType",
          CareEsioEntity.CareAssessment.EntityName
        );
      }
    } else {
      setFormValue(rafForm, "Assessment", null);
      setFormValue(rafForm, "AssessmentUID", null);
      setFormValue(rafForm, "AssessmentType", null);
    }
  };
  const getSupportNeedsDropdown = () => {
    const { initialValues } = props;
    const { careAssessments } = state;
    return (
      <div className="col-md-12">
        <RAFDropdown
          field={"AssessmentUID"}
          showLabel
          //uitype="checkbox"
          onChanged={(lable, value) => onSupportNeedSelected(lable, value)}
          label="Link Support Need"
          //formGroupClassName="mb-0"
          allowFiltering
          disabled={IsNullOrWhiteSpace(initialValues["AssessmentUID"]) ? false : true}
          required
        >
          {isNotEmptyArray(careAssessments) && careAssessments.map((opt) => {
            return (
              <RAFChoiceOption key={opt.UID} label={opt.title} value={opt.UID} />
            );
          })}
        </RAFDropdown>
      </div>
    );
  };
  const getTypeActivityDropdown = () => {
    if (props.riskType === RAFRiskRegisterType.HealthCondition) {
      let customFilter: RAFCustomFilter = {};
      customFilter.Condition = "and";
      customFilter.Rules = [];
      let filter: RAFCustomFilter = {};
      let filterVal: string[] = [];
      filterVal.push(RAFRiskRegisterType.HealthCondition);
      filter.Operator = RAFCustomOperator.Equal;
      filter.Value = filterVal;
      filter.Field = "Type";
      customFilter.Rules.push(filter);

      return (
        <RAFAutoCompleteMUI
          label="Select the type of condition"
          field="Title"
          placeholder="Select the type of condition"
          showLabel
          //showCreateButton
          url="StandardizedLibrary/LookUp"
          createform={RAFCreate}
          moduleName={RAFEntityName.StandardizedLibrary}
          customFilter={customFilter}
          SearchCreateOptionMode={"Footer"}
          required
          formGroupClassName="mb-0"
        />
      );
    } else if (props.riskType === RAFRiskRegisterType.Risk) {
      return (
        <RAFTextBox
          field="Title"
          label="Title"
          showLabel
          required
          formGroupClassName="mb-0"
        />
      );
    }
  };

  if (props.isActive) {
    if (state.isLoading === false) {
      let riskType = props.riskType.toLowerCase();
      return (
        <div
          className="h-100"
          key={props.moduleName}
          id={`raf_dlg_Outer_Div_${props.moduleName}`}
        >
          <RAFEntityProvider moduleName={props.moduleName}>
            <RAFObjectContextProvider
              moduleName={props.moduleName}
              progressDivId={`#raf_dlg_Outer_Div_${props.moduleName}`}
            >
              <RAFObjectContext.Consumer>
                {({ /*objectId, rafObject,*/ saveObject }) => {
                  return (
                    <RAFForm
                      initialValues={state.initialValues}
                      formRef={(g) => (rafForm = g)}
                      layout={RAFLayout.TwoColumnLayout}
                      onSubmit={(values) => onSubmitObject(values, saveObject)}
                      className="h-100"
                    //decorators={[accountDecorators]}
                    //convertBeforeSave={ConvertAccountRowToAccountDto}
                    >
                      <div className="e-dlg-content-outer">
                        <div
                          className={`e-dlg-body-content${state.selectedItemIndex === 1 ? " p-0" : ""
                            }`}
                        >
                          {state.selectedItemIndex === 0 && (
                            <div className="row gy-3 justify-content-center">
                              <div className="col-auto">
                                <div className="header-text-lg">
                                  {`Please specify the type of ${riskType} you'd like to`}
                                  add?
                                </div>
                              </div>
                              <div className="col-12">
                                <CustomCardWidget
                                  onClick={() => onSelectGeneralRisk()}
                                  cardClickable
                                  cardContentClassName="d-flex align-items-center flex-column justify-content-center"
                                >
                                  <div className="raf-avatar raf-avatar-xl circle mb-2">
                                    <img src={classicFormImg}></img>
                                  </div>
                                  <div className="header-text">
                                    {`General ${props.riskType}`}
                                  </div>
                                  <div className="secondary-header-text text-center">
                                    {`This could be a broad ${riskType} that encompasses
                                    various aspects of the individual's
                                    well-being.`}
                                  </div>
                                </CustomCardWidget>
                              </div>
                              <div className="col-12">
                                <CustomCardWidget
                                  cardClickable
                                  cardContentClassName="d-flex align-items-center flex-column justify-content-center"
                                  onClick={() => onSelectContentBtn("Next")}
                                >
                                  <div className="raf-avatar raf-avatar-xl circle mb-2">
                                    <img src={cardFormImg}></img>
                                  </div>
                                  <div className="header-text">
                                    {`Domain-Related ${props.riskType}`}
                                  </div>
                                  <div className="secondary-header-text text-center">
                                    {`This refers to a ${riskType} specifically tied to a
                                    particular domain or category such as
                                    mobility, self-care, communication, etc.`}
                                  </div>
                                </CustomCardWidget>
                              </div>
                            </div>
                          )}
                          {state.selectedItemIndex === 1 && (
                            <div className="">
                              <CareCategoryAndSubCategory
                                categorySelected={() =>
                                  onSelectContentBtn("Next")
                                }
                                entityName={
                                  CareEsioEntity.Assessment.EntityName
                                }
                                progressDivId={`#raf_dlg_Outer_Div_${props.moduleName}`}
                                isActive
                              />
                            </div>
                          )}
                          {state.selectedItemIndex === 2 && (
                            <>
                              <div className="row g-3">
                                {props.riskType === RAFRiskRegisterType.Risk &&
                                  false && (
                                    <>
                                      <div className="col-sm-6">
                                        <Field name={"Category"}>
                                          {({ input, meta }) => {
                                            return (
                                              <RAFDetailFieldCustom
                                                title="Category"
                                                value={
                                                  IsNotNullOrWhiteSpace(
                                                    input.value
                                                  )
                                                    ? input.value
                                                    : "General"
                                                }
                                              />
                                            );
                                          }}
                                        </Field>
                                      </div>
                                      <div className="col-sm-6">
                                        <Field name={"SubCategory"}>
                                          {({ input, meta }) => {
                                            return (
                                              <RAFDetailFieldCustom
                                                title="Sub Category"
                                                value={
                                                  IsNotNullOrWhiteSpace(
                                                    input.value
                                                  )
                                                    ? input.value
                                                    : "Not set"
                                                }
                                              />
                                            );
                                          }}
                                        </Field>
                                      </div>
                                    </>
                                  )}

                                <div className="row g-3 gy-2">
                                  {getFormFields()}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        {state.selectedItemIndex !== 0 && (
                          <div className="e-dlg-footerContent">
                            <div className="w-100">
                              <div className="row gx-2">
                                {state.showBackBtn &&
                                  props.riskType === RAFRiskRegisterType.Risk &&
                                  false && (
                                    <div className="col-auto">
                                      <div className="col-auto ms-auto">
                                        <RAFButtonComponent
                                          type="button"
                                          action={RAFButtonConstant.Previous}
                                          onClick={() =>
                                            onSelectContentBtn("Previous")
                                          }
                                          idString="next_Content"
                                          className="e-outline"
                                        />
                                      </div>
                                    </div>
                                  )}
                                {state.selectedItemIndex === 2 && (
                                  <div className="col-auto ms-auto">
                                    <RAFPermissionRender
                                      permissionName={
                                        RiskRegisterPermissionConstants.RiskRegisterAdd
                                      }
                                    >
                                      <RAFButtonComponent
                                        type="button"
                                        isPrimary
                                        action={RAFButtonConstant.Save}
                                        onClick={() =>
                                          rafForm && rafForm.form.submit()
                                        }
                                        idString="CreateContent"
                                        disabled={rafForm && rafForm.submitting}
                                      />
                                    </RAFPermissionRender>
                                  </div>
                                )}
                                {state.selectedItemIndex === 2 && (
                                  <div className="col-auto">
                                    <RAFButtonComponent
                                      type="button"
                                      action={RAFButtonConstant.Cancel}
                                      onClick={props.onClose}
                                      idString="CreateContent"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </RAFForm>
                  );
                }}
              </RAFObjectContext.Consumer>
            </RAFObjectContextProvider>
          </RAFEntityProvider>
        </div>
      );
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Preparing Data..." />
        </div>
      );
    }
  }
}

export default React.memo(CreateRisk);
