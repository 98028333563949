import * as R from "ramda";
import React, {
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { FormRenderProps } from "react-final-form";
import { RolePermissionsContext } from "../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import RAFStartDateEndDatePicker from "../../../RAFComponents/Inputs/RAFStartDateEndDatePicker";
import RAFTextArea from "../../../RAFComponents/Inputs/RAFTextArea";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFAttributeRelatedListProvider from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import {
  IDialogProps,
  isNotNullAndUndefined,
  propertyOf,
} from "../../../RAFComponents/helpers/utils";
import { RAFUIType } from "../../../RAFComponents/models/Common/RAFDataType";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { ServiceTransactionPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  RAFButtonConstant,
  RAFLayout,
  RAFShiftActionStatus,
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { checkHasPermissionToEditShift } from "../../ActiveContacts/ServiceTransaction/ServiceTransactionHelper";
import { ServiceTransactionRow } from "../../ActiveContacts/ServiceTransaction/ServiceTransactionRow";
import RAFEntityProvider from "../../Common/Providers/RAFEntityProvider";
import { getUserTenantSettings } from "../../Common/TenantSettings/TenantSettingsHelper";
import { TenantSettingsRow } from "../../Common/TenantSettings/TenantSettingsRow";
import {
  createUpdateServiceTransactionRow,
  getTimeChangeRequestRowByServiceTransactionUID,
} from "./TimeChangeRequestHelper";
import { TimeChangeRequestRow } from "./TimeChangeRequestRow";

interface IProps {
  serviceTransactionRow: ServiceTransactionRow;
}

interface IState {
  isLoading: boolean;
  noContent: boolean;
  allowEdit: boolean;
  initialValues: TimeChangeRequestRow;
  tenantSettings: TenantSettingsRow;
}

function ManageTimeChangeRequest({
  ...props
}: PropsWithChildren<IProps & IDialogProps>) {
  const outerDivId = "manage_time_change_request_outerDiv";

  const moduleName = CareEsioEntity.TimeChangeRequest.EntityName;

  let rafFormRef: FormRenderProps | null;

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      noContent: false,
      initialValues: null,
      tenantSettings: null,
      allowEdit: false,
    }
  );

  useEffect(() => {
    refresh();
  }, [props.serviceTransactionRow]);

  const refresh = async () => {
    setState({ isLoading: true });
    if (isNotNullAndUndefined(props.serviceTransactionRow)) {
      const { serviceTransactionRow } = props;

      const [timeChangeRequestRow, tenantSettings] = await Promise.all([
        getTimeChangeRequestRowByServiceTransactionUID(
          serviceTransactionRow.UID
        ),
        getUserTenantSettings(),
      ]);

      const initialValues = new TimeChangeRequestRow();
      if (isNotNullAndUndefined(timeChangeRequestRow)) {
        initialValues.UID = timeChangeRequestRow.UID;
      }

      initialValues.RelatedTo = serviceTransactionRow.Title;
      initialValues.RelatedToUID = serviceTransactionRow.UID;
      initialValues.RelatedToType = RAFEntityName.ServiceTransaction;

      initialValues.Status = RAFShiftActionStatus.AwaitingForApproval;
      initialValues.Assignee = serviceTransactionRow.Assignee;
      initialValues.AssigneeUID = serviceTransactionRow.AssigneeUID;

      initialValues.StartDate = serviceTransactionRow.ActualStartDate;
      initialValues.EndDate = serviceTransactionRow.ActualEndDate;

      const allowshifttimechange = isNotNullAndUndefined(tenantSettings)
        ? tenantSettings.Allowshifttimechange === "Yes"
        : false;

      const noContent =
        serviceTransactionRow.Status === RAFShiftActionStatus.Completed
          ? false
          : true;

      setState({
        noContent,
        initialValues,
        tenantSettings,
        isLoading: false,
        allowEdit: allowshifttimechange,
      });
    } else {
      setState({ noContent: true, isLoading: false });
    }
  };

  const onSubmitForm = async (formValue: TimeChangeRequestRow) => {
    const { serviceTransactionRow } = props;
    let progressDiv = showProgress(`#${outerDivId}`);
    const value = R.clone(formValue);

    const actualStartDate = serviceTransactionRow.ActualStartDate;
    const actualEndDate = serviceTransactionRow.ActualEndDate;

    const formStartDate = value.StartDate;
    const formEndDate = value.EndDate;

    if (actualStartDate === formStartDate && actualEndDate === formEndDate) {
      showWarningToast("No changes detected. Please make changes to save.");
      hideProgress(progressDiv);
      return;
    }

    const response = await createUpdateServiceTransactionRow(value);

    hideProgress(progressDiv);
    if (props.onSave) {
      props.onSave();
    }
  };

  const getFormContent = () => {
    return (
      <div id={outerDivId}>
        <RAFEntityProvider moduleName={moduleName}>
          <RAFAttributeRelatedListProvider moduleName={moduleName}>
            <RAFForm
              initialValues={state.initialValues}
              layout={RAFLayout.TwoColumnLayout}
              submitOnEnterKey={false}
              formRef={(g) => {
                return (rafFormRef = g);
              }}
              onSubmit={onSubmitForm}
            >
              <div className="row gy-4 g-0 flex-column text-align-center form-group-margin-0">
                <>
                  <div className="col-md-12">
                    <RAFStartDateEndDatePicker
                      field={propertyOf<TimeChangeRequestRow>("StartDate")}
                      endDateField={propertyOf<TimeChangeRequestRow>("EndDate")}
                      label="Start Time"
                      required
                      formGroupClassName="mb-0"
                      interval={1}
                      hideDuration
                      disableDatePicker
                      roundOff
                      updateEndDateOnStartDateChange={false}
                      startDateUItype={RAFUIType.TimeOnly}
                      endDateUItype={RAFUIType.TimeOnly}
                    />
                  </div>
                  <div className="col-md-12">
                    <RAFTextArea<TimeChangeRequestRow>
                      field={propertyOf<TimeChangeRequestRow>("Reason")}
                      label="Add Reason"
                      showLabel
                      required
                      placeholder={`Add reason for the ${CareEsioEntity.TimeChangeRequest.DisplayName}.`}
                      rows={5}
                      useMentions={false}
                    />
                  </div>
                </>
              </div>
            </RAFForm>
          </RAFAttributeRelatedListProvider>
        </RAFEntityProvider>
      </div>
    );
  };

  if (state.isLoading === false) {
    if (state.noContent === false) {
      const { tenantSettings } = state;
      const hasPermissionToEdit = checkHasPermissionToEditShift(
        tenantSettings,
        props.serviceTransactionRow,
        permissionValue,
        ServiceTransactionPermissionConstants.ServiceTransactionMyShiftsRequestTimeChange
      );

      if (
        isNotNullAndUndefined(hasPermissionToEdit) &&
        hasPermissionToEdit.hasPermissionToEdit
      ) {
        return (
          <div className="h-100" key={moduleName}>
            <div className="e-dlg-content-outer">
              <div className="e-dlg-body-content">{getFormContent()}</div>
              <div className="e-dlg-footerContent ">
                <RAFButtonComponent
                  action={RAFButtonConstant.Submit}
                  showIcon={false}
                  className="btn_state_success semi_dark form-custom-button"
                  onClick={() => rafFormRef && rafFormRef.form.submit()}
                  type="submit"
                />
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="e-dlg-content-outer">
            <div className="e-dlg-body-content">
              <RAFDeletedRecordState
                title={`${hasPermissionToEdit.warningMessage}`}
              />
          </div>
          </div>
        );
      }
    } else {
      return (
        <div className="e-dlg-content-outer">
          <div className="e-dlg-body-content">
            <RAFDeletedRecordState title="You do not have sufficient privileges to edit this item." />
        </div></div>
      );
    }
  } else {
    return (
      <div className="container-fluid px-0">
        <ACLoadingPanel loadingText="Preparing Data..." />
      </div>
    );
  }
}

export default React.memo(ManageTimeChangeRequest);
