import { getRelatedRecords } from "../../../RAFComponents/helpers/AppHelper";
import { getSaveRequest, isNotEmptyArray, isNotNullAndUndefined, propertyOf } from "../../../RAFComponents/helpers/utils";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import { ContentType } from "../../../constants/Common/Constants";
import { TimeChangeRequestRow } from "./TimeChangeRequestRow";

export const getTimeChangeRequestRowByServiceTransactionUID = (serviceTransactionUID: string) => {
    return new Promise<TimeChangeRequestRow>(async (resolve) => {
        const timeChangeRequestModuleName = CareEsioEntity.TimeChangeRequest.EntityName;

        const timeChangeRequestItems = await getRelatedRecords(timeChangeRequestModuleName, null, propertyOf<TimeChangeRequestRow>('RelatedToUID'), serviceTransactionUID, null, timeChangeRequestModuleName);

        if (isNotEmptyArray(timeChangeRequestItems)) {
            resolve(timeChangeRequestItems[0]);
        } else {
            resolve(null);
        }
    });
};

export const createUpdateServiceTransactionRow = (
    initialValues: TimeChangeRequestRow
) => {
    return new Promise<{ entityId: string; objectName: string; }>((resolve) => {
        if (isNotNullAndUndefined(initialValues)) {
            repositoryActions
                .postDataAndGetResponse(
                    "TimeChangeRequest/Save",
                    getSaveRequest(initialValues, initialValues.UID),
                    null,
                    ContentType.applicationJson
                )
                .then((response) => {
                    if (
                        isNotNullAndUndefined(response) &&
                        isNotNullAndUndefined(response.data) &&
                        isNotNullAndUndefined(response.data.EntityId)
                    ) {
                        resolve({
                            entityId: response.data.EntityId,
                            objectName: response.data.ObjectName,
                        });
                    } else {
                        resolve(null);
                    }
                })
                .catch((error) => resolve(null));
        } else {
            resolve(null);
        }
    });
};