import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, { PropsWithChildren, Reducer, useEffect, useReducer } from "react";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFEmptyState from "../../../RAFComponents/Navigation/RAFEmptyState";
import RAFAttributeRelatedListProvider from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { getAllAttributes, getFields, getRelatedRecords } from "../../../RAFComponents/helpers/AppHelper";
import { ConvertSystemName, isNotEmptyArray, isNotNullAndUndefined, propertyOf } from "../../../RAFComponents/helpers/utils";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { getEntityByObjectName } from "../../../RAFMaster/helpers/RMutils";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import { RAFButtonConstant } from "../../../constants/Common/Constants";
import RAFEntityProvider from "../../Common/Providers/RAFEntityProvider";
import ManageRecurringConfig from "./ManageRecurringConfig";
import RecurringConfigCardContent from "./RecurringConfigCardContent";
import { RecurringConfigRow } from "./RecurringConfigRow";

interface IProps {
    relatedToUID: string;
    relatedTo: string;
    relatedToType: string;
}

interface IState {
    isLoading: boolean;
    recurringConfigItems: RecurringConfigRow[];
    recurringConfigEntity: EntityRow;
    showCreateRecurringConfigContent: boolean;
}

function RecurringConfigList({ ...props }: PropsWithChildren<IProps>) {
    const moduleName = CareEsioEntity.RecurringConfig.EntityName;

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
        (state, newState) => ({ ...state, ...newState }),
        {
            isLoading: true,
            recurringConfigItems: [],
            recurringConfigEntity: null,
            showCreateRecurringConfigContent: false,
        }
    );

    useEffect(() => {
        refresh();
    }, [props.relatedToUID]);

    const refresh = async () => {
        setState({ isLoading: true, showCreateRecurringConfigContent: false });
        if (isNotNullAndUndefined(props.relatedToUID)) {
            const [recurringConfigEntity, attributeRelatedList, queryAttributes] = await Promise.all([
                getEntityByObjectName({
                    ObjectName: ConvertSystemName(moduleName),
                }),
                getAllAttributes(ConvertSystemName(moduleName)),
                getFields(ConvertSystemName(moduleName)),
            ]);//dont remove this line  details filed is used in loop below this line add to avoid multiple calls

            const recurringConfigItems =
                await getRelatedRecords(
                    moduleName,
                    null,
                    propertyOf<RecurringConfigRow>('RelatedToUID'),
                    props.relatedToUID,
                    recurringConfigEntity.UID
                );

            setState({ isLoading: false, recurringConfigItems, recurringConfigEntity });
        } else {
            setState({ isLoading: false, recurringConfigItems: [] });
        }
    };

    const refreshOnupdate = async () => {
        setState({ showCreateRecurringConfigContent: false });
        const recurringConfigItems =
            await getRelatedRecords(
                moduleName,
                null,
                propertyOf<RecurringConfigRow>('RelatedToUID'),
                props.relatedToUID,
                state.recurringConfigEntity.UID
            );
        setState({ recurringConfigItems });

    };

    const headerContent = () => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                <div className="header_5">
                    {CareEsioEntity.RecurringConfig.CollectionName}
                </div>
                <div>
                    {state.isLoading === false && (
                        <div className="d-flex justify-content-center">
                            <RAFButtonComponent
                                action={RAFButtonConstant.Add}
                                onClick={() => onClickAddRecurringConfig()}
                                className="btn_brand_primary semi_dark"
                            />
                            {createRecurringConfigDlgDiv()}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const onClickAddRecurringConfig = () => {
        setState({ showCreateRecurringConfigContent: true });
    };

    const createRecurringConfigContent = () => {
        if (state.showCreateRecurringConfigContent) {

            return (
                <ManageRecurringConfig
                    relatedTo={props.relatedTo}
                    relatedToUID={props.relatedToUID}
                    relatedToType={props.relatedToType}
                    onSave={refreshOnupdate}
                    onClose={closeCreateRecurringConfigDialog}
                    isActive
                />
            );
        } else {
            return <></>;
        }
    };

    const closeCreateRecurringConfigDialog = () => {
        setState({ showCreateRecurringConfigContent: false });
    };

    const createRecurringConfigDlgDiv = () => {
        return (
            state.showCreateRecurringConfigContent && (
                <DialogComponent
                    header={`Add ${CareEsioEntity.RecurringConfig.DisplayName}`}
                    showCloseIcon
                    visible={state.showCreateRecurringConfigContent}
                    cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
                    id={`dlg_update_${moduleName}`}
                    content={createRecurringConfigContent.bind(this)}
                    isModal
                    target="body"
                    closeOnEscape={false}
                    close={closeCreateRecurringConfigDialog.bind(this)}
                    zIndex={1200}
                    open={PreventFocusOnDialogOpen}
                ></DialogComponent>
            )
        );
    };

    const recurringConfigListItem = () => {
        if (isNotEmptyArray(state.recurringConfigItems)) {
            return (
                <div className="col-12">
                    <div className="custom__card__content p-0">
                        <div className="row g-0 gy-2 gy-md-3">
                            {state.recurringConfigItems.map((item) => {
                                return (
                                    <RecurringConfigCardContent
                                        key={item.UID}
                                        recurringConfigRow={item}
                                        recurringConfigEntity={state.recurringConfigEntity}
                                        onSave={refreshOnupdate}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="col-12">
                    <RAFEmptyState
                        title={`No ${CareEsioEntity.RecurringConfig.DisplayName} recorded.`}
                        body={`To get started, please add a new ${CareEsioEntity.RecurringConfig.DisplayName} by clicking on the "Add" button.`}
                    />
                </div>
            );
        }
    };

    const getRecurringConfigListContent = () => {
        return (
            <div className="mt-3">
                <div className="row g-0 gy-3">
                    <div className="col-12">
                        {headerContent()}
                    </div>
                    {recurringConfigListItem()}
                </div>
            </div>
        );

    };

    if (state.isLoading === false) {
        return (
            <RAFEntityProvider moduleName={moduleName}>
                <RAFAttributeRelatedListProvider moduleName={moduleName}>
                    <>
                        {getRecurringConfigListContent()}
                    </>
                </RAFAttributeRelatedListProvider>
            </RAFEntityProvider>
        );
    } else {
        return (
            <div className="container-fluid px-0">
                <ACLoadingPanel loadingText="Loading..." />
            </div>
        );
    }
}

export default React.memo(RecurringConfigList);