import * as R from "ramda";
import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
} from "react";
import { FormRenderProps } from "react-final-form";
import RAFForm, { ConditionNotNullOrWhiteSpace } from "../../../RAFComponents/Inputs/RAFForm";
import RAFTextArea from "../../../RAFComponents/Inputs/RAFTextArea";
import { getFormatedDate, setFormValue } from "../../../RAFComponents/Inputs/RFFUtils";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFDetailFieldCustom from "../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import {
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  IDialogProps,
  isNotNullAndUndefined,
  propertyOf,
} from "../../../RAFComponents/helpers/utils";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { ServiceTransactionPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  RAFButtonConstant,
  RAFLayout,
  RAFShiftActionStatus,
} from "../../../constants/Common/Constants";
import RAFEntityProvider from "../../Common/Providers/RAFEntityProvider";
import {
  createUpdateServiceTransactionRow,
  getTimeChangeRequestRowByServiceTransactionUID,
} from "./TimeChangeRequestHelper";
import { TimeChangeRequestRow } from "./TimeChangeRequestRow";

interface IProps {
  serviceTransactionUID: string;
  actualStartDate: Date;
  actualEndDate: Date;
}

interface IState {
  isLoading: boolean;
  noContent: boolean;
  initialValues: TimeChangeRequestRow;
  timeChangeRequestRow: TimeChangeRequestRow;
}

function ApproveRejectTimeChangeRequestStatus({
  ...props
}: PropsWithChildren<IProps & IDialogProps>) {
  const outerDivId = "manage_time_change_request_outerDiv";

  const moduleName = CareEsioEntity.TimeChangeRequest.EntityName;

  let rafFormRef: FormRenderProps | null;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      noContent: false,
      initialValues: null,
      timeChangeRequestRow: null,
    }
  );

  useEffect(() => {
    refresh();
  }, [props.actualEndDate, props.actualStartDate, props.serviceTransactionUID]);

  const refresh = async () => {
    setState({ isLoading: true });
    if (isNotNullAndUndefined(props.serviceTransactionUID)) {
      const timeChangeRequestRow =
        await getTimeChangeRequestRowByServiceTransactionUID(
          props.serviceTransactionUID
        );
      if (isNotNullAndUndefined(timeChangeRequestRow)) {
        const initialValues = new TimeChangeRequestRow();

        initialValues.UID = timeChangeRequestRow.UID;
        initialValues.Status = RAFShiftActionStatus.Approved;
        initialValues.Reason = timeChangeRequestRow.Reason;

        setState({ noContent: false, initialValues, timeChangeRequestRow, isLoading: false });
      } else {
        setState({ noContent: true, isLoading: false, timeChangeRequestRow: null });
      }
    } else {
      setState({ noContent: true, isLoading: false, timeChangeRequestRow: null });
    }
  };

  const onSubmitForm = async (formValue: TimeChangeRequestRow) => {
    let progressDiv = showProgress(`#${outerDivId}`);
    const value = R.clone(formValue);

    const response = await createUpdateServiceTransactionRow(value);

    hideProgress(progressDiv);
    if (props.onSave) {
      props.onSave();
    }
  };

  const getStartEndTimeDisplayText = (
    startDate: Date,
    endDate: Date
  ) => {
    if (isNotNullAndUndefined(startDate) && isNotNullAndUndefined(endDate)) {
      const monthValue = getFormatedDate(startDate, "MMM");
      const dateValue = getFormatedDate(startDate, "DD");
      const startDateTime = getFormatedDate(startDate, "hh:mm a");
      const endDateTime = getFormatedDate(endDate, "hh:mm a");

      return (
        <div>
          <span className="header-text-sm">{`${monthValue} ${dateValue},`}</span>
          <span className="header-text-sm-light ps-1">{`${startDateTime} - ${endDateTime}`}</span>
        </div>
      );
    } else {
      return null;
    }
  };

  const getActualRequestedTime = () => {
    const { timeChangeRequestRow } = state;

    return (
      <>
        <div className="col-md-12">
          <RAFDetailFieldCustom
            title="Pay Time"
            colClassName="col-12"
            labelDivClassName="col-md-3"
            valueDivClassName="col-md-9"
            customValue={
              getStartEndTimeDisplayText(props.actualStartDate, props.actualEndDate)
            }
          />
        </div>
        <div className="col-md-12">
          <RAFDetailFieldCustom
            title="Requested Time"
            colClassName="col-12"
            labelDivClassName="col-md-3"
            valueDivClassName="col-md-9"
            customValue={
              getStartEndTimeDisplayText(timeChangeRequestRow.StartDate, timeChangeRequestRow.EndDate)
            }
          />
        </div>
      </>
    );
  };

  const getFormContent = () => {
    return (
      <div id={outerDivId}>
        <RAFEntityProvider moduleName={moduleName}>
          <RAFForm
            initialValues={state.initialValues}
            layout={RAFLayout.TwoColumnLayout}
            submitOnEnterKey={false}
            formRef={(g) => {
              return (rafFormRef = g);
            }}
            onSubmit={onSubmitForm}
          >
            <div className="row gy-4 g-0 flex-column text-align-center form-group-margin-0">
              <>
                {/* <div className="col-md-12">
                  <RAFRadioButtonList
                    field={propertyOf<TimeChangeRequestRow>("Status")}
                    label="Status"
                    showLabel={false}
                    labelClassName="col-auto"
                    inputFieldClassName="col-auto radio-btn-m-0"
                    formGroupClassName="mb-0"
                    uitype="customButton"
                  >
                    <RAFChoiceOption
                      label="Approve"
                      value={RAFShiftActionStatus.Approved}
                    ></RAFChoiceOption>
                    <RAFChoiceOption
                      label="Reject"
                      value={RAFShiftActionStatus.Rejected}
                    ></RAFChoiceOption>
                  </RAFRadioButtonList>
                </div> */}
                {getActualRequestedTime()}
                <ConditionNotNullOrWhiteSpace when={propertyOf<TimeChangeRequestRow>("Reason")}>
                  <div className="col-md-12">
                    <RAFTextArea<TimeChangeRequestRow>
                      field={propertyOf<TimeChangeRequestRow>("Reason")}
                      label="Reason"
                      showLabel
                      placeholder={`Add reason for the ${CareEsioEntity.TimeChangeRequest.DisplayName}.`}
                      rows={5}
                      useMentions={false}
                      disabled
                    />
                  </div>
                </ConditionNotNullOrWhiteSpace>
              </>
            </div>
          </RAFForm>
        </RAFEntityProvider>
      </div>
    );
  };

  const onClickApprove = () => {
    setFormValue(rafFormRef, 'Status', RAFShiftActionStatus.Approved);
    rafFormRef && rafFormRef.form.submit();
  };
  const onClickReject = () => {
    setFormValue(rafFormRef, 'Status', RAFShiftActionStatus.Rejected);
    rafFormRef && rafFormRef.form.submit();
  };

  if (state.isLoading === false) {
    if (state.noContent === false) {
      return (
        <RAFPermissionRender
          permissionName={ServiceTransactionPermissionConstants.ServiceTransactionApproveTimeChange}
          noPermissionContet={
            <div className="e-dlg-content-outer">
              <div className="p-3 py-2 columnChooserPanel customScrollBar">
                <RAFDeletedRecordState
                  title={'You do not have sufficient privileges to approve or reject this item.'}
                />
              </div>
            </div>
          }
        >
          <div className="h-100" key={moduleName}>
            <div className="e-dlg-content-outer">
              <div className="e-dlg-body-content">{getFormContent()}</div>
              <div className="e-dlg-footerContent ">
                <RAFButtonComponent
                  action={RAFButtonConstant.Reject}
                  showIcon={false}
                  className="btn_state_danger semi_dark form-custom-button"
                  onClick={onClickReject}
                ></RAFButtonComponent>
                <RAFButtonComponent
                  action={RAFButtonConstant.Approve}
                  showIcon={false}
                  className="btn_state_success semi_dark form-custom-button"
                  onClick={onClickApprove}
                ></RAFButtonComponent>
                {/* <ButtonComponent
                className="me-2"
                cssClass="e-flat e-info"
                onClick={() => rafFormRef && rafFormRef.form.submit()}
              >
                OK
              </ButtonComponent>
              <ButtonComponent
                type="button"
                cssClass="e-flat"
                onClick={() => props.onClose()}
              >
                Cancel
              </ButtonComponent> */}
              </div>
            </div>
          </div>
        </RAFPermissionRender>
      );
    } else {
      return (
        <div className="e-dlg-content-outer">
          <div className="p-3 py-2 columnChooserPanel customScrollBar">
            <RAFDeletedRecordState title="You do not have sufficient privileges to edit this item." />
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className="container-fluid px-0">
        <ACLoadingPanel loadingText="Preparing Data..." />
      </div>
    );
  }
}

export default React.memo(ApproveRejectTimeChangeRequestStatus);
