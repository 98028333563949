import { DialogComponent } from "@syncfusion/ej2-react-popups";
import React, {
  forwardRef,
  PropsWithChildren,
  Reducer,
  useEffect,
  useImperativeHandle,
  useReducer,
} from "react";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import {
  CareEsioEntity,
  CareEsioTab,
} from "../../../constants/CareESIO/CareEsioConstants";
import { RiskRegisterPermissionConstants } from "../../../constants/CareESIO/CareESIOPermissionConstant";
import {
  ContentType,
  RAFButtonConstant,
  RAFRiskRegisterType,
} from "../../../constants/Common/Constants";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import { getAllAttributes, getFields, getRelatedRecords } from "../../../RAFComponents/helpers/AppHelper";
import RAFPermissionRender from "../../../RAFComponents/helpers/PermissionHelper";
import {
  RAFEventName,
  subscribeRAFEvent,
  triggerRAFEvent,
  unsubscribeRAFEvent,
} from "../../../RAFComponents/helpers/RAFDocumentEvents";
import {
  ConvertSystemName,
  getSaveRequest,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { RAFDataType } from "../../../RAFComponents/models/Common/RAFDataType";
import CustomCardWidget from "../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFCollapse from "../../../RAFComponents/Navigation/RAFCollapse";
import RAFCollapseWithPlusIcon from "../../../RAFComponents/Navigation/RAFCollapseWithPlusIcon";
import RAFDetailFieldEditableCustom from "../../../RAFComponents/Navigation/RAFDetailFieldEditableCustom";
import RAFEmptyState from "../../../RAFComponents/Navigation/RAFEmptyState";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { getEntityByObjectName } from "../../../RAFMaster/helpers/RMutils";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { RAFObjectContext } from "../../Common/Providers/RAFObjectContextProvider";
import { getDefaultCareAssessmentFieldValue } from "../CareAssessmentLibrary/CareAssessmentHelper";
import UpdateCareRecipient from "../CareRecipient/Details/UpdateCareRecipient";
import CreateRisk from "./CreateRisk";
import HealthConditionContent from "./HealthConditionContent";

interface IProps {
  careRecipientUID: string;
  careRecipientRow?: any;
  careAssessmentUID?: string;
  showEmptyState?: boolean;
  showAddBtn?: boolean;
  careAssessmentRow?: any;
  uiStyle?:
  | "CardStyle"
  | "DetailsStyle"
  | "DetailsWithHeader"
  | "ProfileSummaryStyle"
  | "SummaryStyle"
  | "SummaryDetails";
  onRiskUpdated?: () => void;

  paddingClass?: string;
  guttersClass?: string;
  collapsible?: boolean;
  isCollapsed?: boolean;
  cardClassName?: string;
}

interface IState {
  isLoading: boolean;
  healthConditionItems: any;
  riskRegisterEntity: EntityRow;
  showCreateRiskRegisterContent: boolean;
  defaultFieldValue: string;
}

const HealthConditionList = forwardRef(function HealthConditionList(
  {
    showAddBtn = true,
    showEmptyState = true,
    uiStyle = "CardStyle",
    paddingClass = "p-2-5 p-md-3",
    collapsible = true,
    isCollapsed = false,
    ...props
  }: PropsWithChildren<IProps>,
  ref
) {
  const riskRegisterModule = CareEsioEntity.RiskRegister.EntityName;
  const rafObject = React.useContext(RAFObjectContext);
  const careRecipientRow = isNotNullAndUndefined(rafObject)
    ? rafObject.rafObject
    : props.careRecipientRow;
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      healthConditionItems: null,
      riskRegisterEntity: null,
      showCreateRiskRegisterContent: false,
      defaultFieldValue: null,
    }
  );

  useEffect(() => {
    refresh();
  }, [props.careAssessmentUID]);

  useImperativeHandle(ref, () => ({
    toggleCreateDlg() {
      setState({
        showCreateRiskRegisterContent: !state.showCreateRiskRegisterContent,
      });
    },
  }));

  useEffect(() => {
    const relatedModule = isNotNullAndUndefined(props.careAssessmentUID)
      ? `${CareEsioEntity.CarePlan.EntityName}_${riskRegisterModule}_${RAFRiskRegisterType.HealthCondition}`
      : `${riskRegisterModule}_${RAFRiskRegisterType.HealthCondition}`;

    const eventKey = `${RAFEventName.GridLoad}_${relatedModule}_${props.careRecipientUID}`;

    subscribeRAFEvent(
      eventKey,
      reLoadListContent
    );

    return () => {
      unsubscribeRAFEvent(
        eventKey,
        reLoadListContent
      );
    };
  });

  const reLoadListContent = (args) => {
    if (isNotNullAndUndefined(args) && isNotNullAndUndefined(args.detail)) {
      const argsUIStyle = args.detail.uiStyle;
      if (argsUIStyle === uiStyle) {
        refresh();
      }
    };
  };

  const refresh = async () => {
    if (
      isNotNullAndUndefined(props.careAssessmentUID) ||
      isNotNullAndUndefined(props.careRecipientUID)
    ) {
      setState({
        isLoading: true,
        healthConditionItems: null,
        showCreateRiskRegisterContent: false,
      });

      const [riskRegisterEntity, attributeRelatedList, queryAttributes] = await Promise.all([
        getEntityByObjectName({
          ObjectName: ConvertSystemName(riskRegisterModule),
        }),
        getAllAttributes(ConvertSystemName(riskRegisterModule)),
        getFields(ConvertSystemName(riskRegisterModule)),
      ]);//dont remove this line  details filed is used in loop below this line add to avoid multiple calls


      const healthConditionItems = isNotNullAndUndefined(
        props.careAssessmentUID
      )
        ? await getRelatedRecords(
          riskRegisterModule,
          null,
          "AssessmentUID",
          props.careAssessmentUID,
          riskRegisterEntity.UID
        )
        : await getRelatedRecords(
          riskRegisterModule,
          null,
          "CareRecipientUID",
          props.careRecipientUID,
          riskRegisterEntity.UID
        );

      const defaultFieldValue = await getDefaultCareAssessmentFieldValue(
        props.careAssessmentRow
      );

      let filteredRiskRegisterItems;

      if (isNotEmptyArray(healthConditionItems)) {
        filteredRiskRegisterItems = healthConditionItems.filter(
          (x) => x.RiskType === RAFRiskRegisterType.HealthCondition
        );
      }

      setState({
        isLoading: false,
        healthConditionItems: filteredRiskRegisterItems,
        defaultFieldValue,
        riskRegisterEntity,
      });
    }
  };

  const onSaved = () => {
    refresh();
    if (isNotNullAndUndefined(props.onRiskUpdated)) {
      props.onRiskUpdated();
    } else {
      const relatedModule = isNotNullAndUndefined(props.careAssessmentUID)
        ? `${CareEsioEntity.CarePlan.EntityName}_${riskRegisterModule}_${RAFRiskRegisterType.HealthCondition}`
        : `${riskRegisterModule}_${RAFRiskRegisterType.HealthCondition}`;

      const eventKey = `${RAFEventName.GridLoad}_${relatedModule}_${props.careRecipientUID}`;

      triggerRAFEvent(eventKey, {
        uiStyle: `ProfileSummaryStyle`,
      });
    }
  };

  const getHealthConditionHeader = () => {
    if (state.isLoading === false) {
      return (
        <div className="col-12">
          <div className="d-flex justify-content-between">
            <div className="header_5">
              <span className="header_5">
                {CareEsioTab.PermanentCondition.CollectionName}
              </span>
              {/* <div className="body_1 semi_bold">Do you have any diagnosed health conditions?</div>
              <div className="body_1_light">
                List any diagnosed health conditions you have to help us understand and manage your care appropriately.
              </div> */}
            </div>
            <div>
              {state.isLoading === false && showAddBtn === true && (
                <div className="col-12 d-flex justify-content-center">
                  <RAFPermissionRender
                    permissionName={
                      RiskRegisterPermissionConstants.RiskRegisterAdd
                    }
                  >
                    <RAFButtonComponent
                      action={RAFButtonConstant.Add}
                      onClick={() => onClickAddRiskRegister()}
                      className="btn_brand_primary semi_dark"
                    />
                  </RAFPermissionRender>
                  {createRiskDlgDiv()}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return;
    }
  };

  const getHealthConditionListContent = () => {
    const healthConditionCount = isNotEmptyArray(state.healthConditionItems)
      ? state.healthConditionItems.length
      : 0;

    if (uiStyle === "DetailsStyle") {
      return (
        <div className="row gy-3 g-0">
          {getHealthConditionHeader()}
          {healthConditionListItem()}
          {false && (
            <div className="col-12">
              <div className="e-card mt-3" id="detailsPanel">
                <div className="e-card-content">
                  <RAFDetailFieldEditableCustom
                    title="Medical History"
                    value={careRecipientRow.MedicalHistory}
                    onChange={(e) => onChangeCareRecipient("MedicalHistory", e)}
                    valueClassName="labelPrimartyText"
                    type={RAFDataType.Multiline}
                    name="MedicalHistory"
                  ></RAFDetailFieldEditableCustom>
                </div>
              </div>
            </div>
          )}
          <div className="col-12">
            <UpdateCareRecipient
              careRecipientRow={careRecipientRow}
              objectUID={props.careRecipientUID}
              mode={"MedicalHistory"}
            />
          </div>
        </div>
      );
    } else if (uiStyle === "ProfileSummaryStyle") {
      return (
        <div className="" key={healthConditionCount}>
          <div className="row gx-0 gy-2">
            <div className="col-12">
              <div className="d-flex align-items-center">
                <div className="profile_card_details_label">
                  {CareEsioTab.PermanentCondition.CollectionName}
                </div>
                <div
                  className={`ms-2 raf-badge-circle raf-badge${healthConditionCount > 0
                    ? "-tertiary-circle"
                    : "-neutral-circle"
                    }`}
                >
                  {healthConditionCount}
                </div>
              </div>
            </div>
            {healthConditionCount > 0 ? (
              <div className="col-12">{healthConditionListItemSummary()}</div>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    } else if (uiStyle === "SummaryStyle") {
      return (
        <div className="" key={healthConditionCount}>
          <CustomCardWidget cardContentClassName="p-0">
            <RAFCollapseWithPlusIcon
              toggleArrowIcon="Arrow"
              title={CareEsioTab.PermanentCondition.CollectionName}
              collapsePanelHeaderClass={
                "collapsePanel__header__fixedHeight__lg"
              }
              badge={{
                count: healthConditionCount,
                show: true,
                className: "ms-2 raf-badge-circle raf-badge-tertiary-circle",
              }}
              isCollapsed={healthConditionCount > 0 ? false : true}
              collapsible={healthConditionCount > 0 ? true : false}
              collapsePanelContentClassname={paddingClass}
            >
              {healthConditionListItemSummary()}
            </RAFCollapseWithPlusIcon>
          </CustomCardWidget>
        </div>
      );
    } else if (
      uiStyle === "SummaryDetails"
      //  ||
      // uiStyle === "SummaryStyle"
    ) {
      return (
        <div className="">
          <CustomCardWidget cardContentClassName="p-0">
            <RAFCollapseWithPlusIcon
              toggleArrowIcon="Arrow"
              title={CareEsioTab.PermanentCondition.CollectionName}
              collapsePanelHeaderClass={
                "collapsePanel__header__fixedHeight__lg"
              }
              // collapsePanelHeaderClass={"px-0"}
              allowFullRowClick
              collapsePanelContentClassname="p-0"
              badge={{
                count: healthConditionCount,
                show: true,
                className: "ms-2 raf-badge-circle raf-badge-tertiary-circle",
              }}
              isCollapsed={
                isNotNullAndUndefined(healthConditionCount) &&
                  healthConditionCount > 0
                  ? true
                  : isCollapsed
              }
              // collapsible={props.collapsible}
              showSeparator={true}
              customButton={
                <div>
                  {showAddBtn !== false && (
                    <div className="d-flex align-items-baseline">
                      <div className="col-auto">
                        <RAFPermissionRender
                          permissionName={
                            RiskRegisterPermissionConstants.RiskRegisterAdd
                          }
                        >
                          <RAFButtonComponent
                            className={`custom-button-sm primary-custom-button`}
                            // className={`custom-button-sm primary-custom-button${!BrowserIsDevice ? " min-width-lg" : ""}`}
                            // className="custom-button-md"
                            action={RAFButtonConstant.Add}
                            // {...!BrowserIsDevice ?
                            //   { btnContent: `Add ${CareEsioEntity.RiskRegister.DisplayName}` }
                            //   :
                            //   {}
                            // }
                            onClick={() => onClickAddRiskRegister()}
                          />
                        </RAFPermissionRender>
                      </div>
                    </div>
                  )}
                </div>
              }
            >
              <div>
                {createRiskDlgDiv()}
                {healthConditionListItem()}
              </div>
            </RAFCollapseWithPlusIcon>
          </CustomCardWidget>
        </div>
      );
    } else {
      const showExpandBtn = state.isLoading
        ? true
        : isNotEmptyArray(state.healthConditionItems)
          ? true
          : false;
      return (
        <div className="col-12">
          <div className="custom__card overflow-hidden bg-light-blue-3">
            <div className="custom__card__content p-0">
              <RAFCollapse
                title={`${CareEsioTab.PermanentCondition.CollectionName}`}
                headerRowClassName="with-height"
                // headerRowClassName="with-height bg-card-header-8"
                contentCardClassName="relatedListOuterDiv"
                headerBadge={
                  isNotEmptyArray(state.healthConditionItems) && (
                    <div className="secondary-text">
                      <span className="e-badge e-badge-grey-border e-badge-circle e-badge-md e-badge-notification ms-2 position-static">
                        {state.healthConditionItems.length}
                      </span>
                    </div>
                  )
                }
                removeContentCardPadding
                IsCollapsed={true}
                customButton={
                  <div>
                    {showAddBtn !== false && (
                      <div className="d-flex align-items-baseline">
                        <div className="col-auto">
                          <RAFPermissionRender
                            permissionName={
                              RiskRegisterPermissionConstants.RiskRegisterAdd
                            }
                          >
                            <RAFButtonComponent
                              className={`custom-button-sm primary-custom-button`}
                              // className={`custom-button-sm primary-custom-button${!BrowserIsDevice ? " min-width-lg" : ""}`}
                              // className="custom-button-md"
                              action={RAFButtonConstant.Add}
                              // {...!BrowserIsDevice ?
                              //   { btnContent: `Add ${CareEsioEntity.RiskRegister.DisplayName}` }
                              //   :
                              //   {}
                              // }
                              onClick={() => onClickAddRiskRegister()}
                            />
                          </RAFPermissionRender>
                        </div>
                      </div>
                    )}
                  </div>
                }
              // Collapsible={showExpandBtn}
              >
                <div>
                  {createRiskDlgDiv()}
                  {healthConditionListItem()}
                </div>
              </RAFCollapse>
            </div>
          </div>
        </div>
      );
    }
  };

  const createRiskDlgDiv = () => {
    if (state.showCreateRiskRegisterContent) {
      return (
        <DialogComponent
          header={`Add ${CareEsioTab.PermanentCondition.CollectionName}`}
          showCloseIcon
          visible={state.showCreateRiskRegisterContent}
          cssClass="rightDialog createEditForm full-height form-center-dialog dlg-new-style"
          id={`dlg_create_${riskRegisterModule}`}
          content={createRiskRegisterContent.bind(this)}
          isModal
          target="body"
          closeOnEscape={false}
          close={closeCreateRiskRegisterDialog.bind(this)}
          zIndex={1200}
          open={PreventFocusOnDialogOpen}
        />
      );
    } else {
      return null;
    }
  };

  //addRisk start
  const onClickAddRiskRegister = async () => {
    setState({ showCreateRiskRegisterContent: true });
  };

  const createRiskRegisterContent = () => {
    if (state.showCreateRiskRegisterContent) {
      const { careAssessmentRow } = props;

      let initialValues = {};
      if (isNotNullAndUndefined(careAssessmentRow)) {
        initialValues["CareRecipientUID"] = careAssessmentRow.CareRecipientUID;
        initialValues["CareRecipient"] = careAssessmentRow.CareRecipient;
        //initialValues['CarePlanSection'] = careAssessmentRow.CarePlan;
        initialValues["CarePlan"] = careAssessmentRow.CarePlan;
        initialValues["CarePlanUID"] = careAssessmentRow.CarePlanUID;
        // initialValues["CarePlanSection"] = careAssessmentRow.Category;
        // initialValues["CarePlanSectionUID"] = careAssessmentRow.UID;
        initialValues["Category"] = careAssessmentRow.Category;
        initialValues["CategoryUID"] = careAssessmentRow.CategoryUID;
        initialValues["SubCategory"] = careAssessmentRow.SubCategory;
        initialValues["SubCategoryUID"] = careAssessmentRow.SubCategoryUID;
        initialValues["Assessment"] = state.defaultFieldValue; // careAssessmentRow. ;
        initialValues["AssessmentUID"] = careAssessmentRow.UID;
        initialValues["AssessmentType"] = careAssessmentRow.Entity;
      } else {
        const objectContext = React.useContext(RAFObjectContext);
        const rafObject = isNotNullAndUndefined(objectContext)
          ? objectContext.rafObject
          : null;
        if (isNotNullAndUndefined(rafObject)) {
          initialValues["CareRecipientUID"] = rafObject.UID;
          initialValues["CareRecipient"] = rafObject.first_name;
          initialValues["RiskType"] = RAFRiskRegisterType.HealthCondition;
        }
      }

      return (
        <CreateRisk
          initialValues={initialValues}
          onSave={onCreateRiskRegisterList.bind(this)}
          onClose={closeCreateRiskRegisterDialog.bind(this)}
          moduleName={riskRegisterModule}
          riskType={RAFRiskRegisterType.HealthCondition}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  };

  const onCreateRiskRegisterList = () => {
    onSaved();
  };

  const closeCreateRiskRegisterDialog = () => {
    setState({ showCreateRiskRegisterContent: false });
  };

  //addRisk end

  const healthConditionListItem = () => {
    if (state.isLoading === false) {
      if (isNotEmptyArray(state.healthConditionItems)) {
        return (
          <>
            <div className="col-12">
              <div>
                <div className={`${paddingClass}`}>
                  <div
                    className={`row${isNotNullAndUndefined(props.guttersClass)
                      ? ` ${props.guttersClass}`
                      : " gy-2 gy-md-3 g-0"
                      }`}
                  >
                    {state.healthConditionItems.map((item) => {
                      return (
                        <HealthConditionContent
                          key={item.UID}
                          riskRegisterRow={item}
                          riskRegisterEntity={state.riskRegisterEntity}
                          onSave={onSaved}
                          riskType={RAFRiskRegisterType.HealthCondition}
                          allowEdit={uiStyle === "SummaryStyle" ? false : true}
                          cardClassName={props.cardClassName}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      } else if (showEmptyState !== false) {
        return (
          <div className="col-12">
            <RAFEmptyState
              title={`No ${CareEsioTab.PermanentCondition.DisplayName} recorded.`}
              {...(showAddBtn === true && {
                body: `To get started, please add a new ${CareEsioTab.PermanentCondition.DisplayName} by clicking on the "Add" button.`,
              })}
            />
          </div>
        );
      } else {
        return <div></div>;
      }
    } else {
      return (
        <div className="col-12">
          <div className="custom__card__content p-0">
            <div className="container-fluid px-0">
              <ACLoadingPanel loadingText="Preparing Data..." />
            </div>
          </div>
        </div>
      );
    }
  };

  const healthConditionListItemSummary = () => {
    if (isNotEmptyArray(state.healthConditionItems)) {
      return (
        <div className="row g-2">
          {state.healthConditionItems.map((item) => {
            return (
              <div className={"col-auto"} key={item.UID}>
                <div className="raf_badge surface_neutral_base">
                  <span className="body_2_dark ecllipseSecondLine">
                    {item.Title}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  function onChangeCareRecipient(fieldName: keyof any, e: any): void {
    (careRecipientRow[fieldName] as any) = e.value;
    let id = careRecipientRow.UID;
    const url = "DataList/Save";
    let saveRequestData = getSaveRequest(careRecipientRow, id);
    saveRequestData.EntityName = CareEsioEntity.CareRecipient.EntityName;
    repositoryActions
      .postDataAndGetResponse(
        url,
        saveRequestData,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (
            response.data.EntityId !== null &&
            response.data.EntityId !== undefined &&
            response.data.EntityId !== ""
          ) {
          } else if (
            response.data.Error !== null &&
            response.data.Error !== undefined &&
            response.data.Error !== ""
          ) {
            //console.log("Error on save", response.data.Error);
          }
        }
      })
      .catch((error) => error);
  }

  const getBodyContent = () => {
    if (
      isNotNullAndUndefined(props.careAssessmentUID) ||
      isNotNullAndUndefined(props.careRecipientUID)
    ) {
      if (showEmptyState === false) {
        if (state.isLoading) {
          return (
            <div className="p-3">
              <div className="container-fluid px-0">
                <ACLoadingPanel loadingText="Preparing Data..." />
              </div>
            </div>
          );
        } else {
          if (isNotEmptyArray(state.healthConditionItems)) {
            return (
              <div className="pt-3">{getHealthConditionListContent()}</div>
            );
          } else {
            return <div>{createRiskDlgDiv()}</div>;
          }
        }
      } else {
        return (
          <div className={uiStyle === "ProfileSummaryStyle" ? "" : "pt-3"}>
            {getHealthConditionListContent()}
          </div>
        );
      }
    } else {
      return <div></div>;
    }
  };

  return (
    <RAFPermissionRender
      permissionName={RiskRegisterPermissionConstants.RiskRegisterRead}
    >
      {getBodyContent()}
    </RAFPermissionRender>
  );
});

export default React.memo(HealthConditionList);
