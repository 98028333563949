export class RecurringConfigRow {
    UID?: string;
    FormUID?: string;
    Form?: string;
    Entity?: string;
    EntityDisplayName?: string;
    RelatedToUID?: string;
    RelatedTo?: string;
    RelatedToType?: string;
    Frequency?: string;
    RruleExpression?: string;
    CronExpression?: string;
    IsRequired?: boolean;
    StartDate?: Date;
    EndDate?: Date;
    TotalOccurrences?: number;
    ScheduleOccurrences?: number;
    RecurringConfigId?: string;
    RecurringConfigIdNumber?: string;
    PortalUID?: string;
    Portal?: string;
    CreatedDate?: Date;
    CreatedBy?: string;
    CreatedByUID?: string;
    ModifiedDate?: Date;
    ModifiedBy?: string;
    ModifiedByUID?: string;
    RecordOwner?: string;
    RecordOwnerUID?: string;
    LastActivityDate?: Date;
}