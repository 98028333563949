import * as R from "ramda";
import React, {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
} from "react";
import { FormRenderProps } from "react-final-form";
import RAFAutoCompleteMUI from "../../../RAFComponents/Inputs/RAFAutoCompleteMUI";
import RAFChoiceOption from "../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFDropdown from "../../../RAFComponents/Inputs/RAFDropdown";
import RAFDropdownCC from "../../../RAFComponents/Inputs/RAFDropdownCC";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import RAFHtmlEditor from "../../../RAFComponents/Inputs/RAFHtmlEditor";
import RAFTextArea from "../../../RAFComponents/Inputs/RAFTextArea";
import RAFTextBox from "../../../RAFComponents/Inputs/RAFTextBox";
import { setFormValue } from "../../../RAFComponents/Inputs/RFFUtils";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFAttributeRelatedListProvider, {
  RAFAttributesContext,
} from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  IDialogProps,
  IsNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { EvaluateScore } from "../../../RAFMaster/helpers/RMutils";
import ACLoadingPanel from "../../../components/shared/ACLoadingPanel";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  RAFButtonConstant,
  RAFLayout,
  RAFRiskRegisterType,
} from "../../../constants/Common/Constants";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { RAFBPCurrentStatus } from "../../../constants/Common/RMConstants";
import RAFCreate from "../../Common/Create";
import RAFEntityProvider, {
  RAFEntityContext,
} from "../../Common/Providers/RAFEntityProvider";
import RAFObjectContextProvider, {
  RAFObjectContext,
} from "../../Common/Providers/RAFObjectContextProvider";
import { getAssessmentListByCarePlanAndRecipientUID } from "../CareAssessmentLibrary/CareAssessmentHelper";

interface IProps {
  objectUID: string;
  onDelete?: () => void;
  riskType?: RAFRiskRegisterType.Risk | RAFRiskRegisterType.HealthCondition;
  riskRegisterRow?: any;
}

interface IState {
  isActive: boolean;
  isLoading: boolean;
  careAssessments: any[];
}

function UpdateRisk({ ...props }: PropsWithChildren<IProps & IDialogProps>) {
  let deleteDialog: any;

  const moduleName = CareEsioEntity.RiskRegister.EntityName;

  const outerDivId = `raf_dlg_Outer_Div_${moduleName}`;
  let rafFormRef: FormRenderProps | null;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isActive: false,
      isLoading: false,
      careAssessments: [],
    }
  );

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    const { isActive, riskRegisterRow } = props;
    if (isActive) {
      setState({ isActive, isLoading: true });

      const careAssessments = await getAssessmentListByCarePlanAndRecipientUID(
        riskRegisterRow["CarePlanUID"],
        riskRegisterRow["CareRecipientUID"],
        CareEsioEntity.CareAssessment.EntityName,
        IsNullOrWhiteSpace(riskRegisterRow["AssessmentUID"]) ? RAFBPCurrentStatus.Draft : null
      );

      setState({
        isLoading: false,
        isActive,
        careAssessments,
      });
    } else {
      setState({ isActive });
    }
  };

  const onSubmitObject = (
    values,
    entity,
    queryAttributes,
    saveObject?: (
      objectData?: any
    ) => Promise<{ objectUID?: string; objectName?: string; }>
  ) => {
    let updatedValues = R.clone(values);

    if (
      isNotNullAndUndefined(entity) &&
      isNotNullAndUndefined(entity.EntitySettings) &&
      isNotNullAndUndefined(entity.EntitySettings.EnableScore) &&
      entity.EntitySettings.EnableScore === true
    ) {
      updatedValues = EvaluateScore(values, queryAttributes);
    }
    saveObject(updatedValues)
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.objectUID)
        ) {
          showSuccessToast("Saved successfully");

          if (props.onSave)
            props.onSave(response.objectUID, response.objectName);
        } else {
          showWarningToast("Sorry something went wrong !");
        }
      })
      .catch((error) => error);
  };

  const onClickDeleteRisk = () => {
    if (isNotNullAndUndefined(props.onDelete)) {
      props.onDelete();
    }
  };

  const onSupportNeedSelected = (label, value) => {
    if (isNotNullAndUndefined(value)) {
      let { careAssessments } = state;
      let selectedAssessment = careAssessments.find((x) => x.UID === value);
      if (isNotNullAndUndefined(selectedAssessment)) {
        setFormValue(rafFormRef, "Assessment", selectedAssessment["title"]);
        setFormValue(rafFormRef, "AssessmentUID", selectedAssessment.UID);
        setFormValue(
          rafFormRef,
          "AssessmentType",
          CareEsioEntity.CareAssessment.EntityName
        );
      }
    } else {
      setFormValue(rafFormRef, "Assessment", null);
      setFormValue(rafFormRef, "AssessmentUID", null);
      setFormValue(rafFormRef, "AssessmentType", null);
    }
  };

  const getSupportNeedsDropdown = () => {
    const { riskRegisterRow } = props;
    const { careAssessments } = state;
    return (
      <div className="col-md-12">
        <RAFDropdown
          field={"AssessmentUID"}
          showLabel
          //uitype="checkbox"
          onChanged={(lable, value) => onSupportNeedSelected(lable, value)}
          label="Link Support Need"
          //formGroupClassName="mb-0"
          allowFiltering
          disabled={IsNullOrWhiteSpace(riskRegisterRow["AssessmentUID"]) ? false : true}
          required
        >
          {isNotEmptyArray(careAssessments) && careAssessments.map((opt) => {
            return (
              <RAFChoiceOption key={opt.UID} label={opt.title} value={opt.UID} />
            );
          })}
        </RAFDropdown>
      </div>
    );
  };

  const getFormFields = () => {
    let { riskType } = props;
    if (riskType === RAFRiskRegisterType.HealthCondition) {
      return (
        <>
          <div className="col-md-12">{getTypeActivityDropdown()}</div>
          <div className="col-md-12">
            <RAFDropdownCC
              field="RiskLevel"
              label="Severity"
              isColorOption
              uitype="colorpicker"
              moduleName={CareEsioEntity.RiskRegister.EntityName}
              displayDropdownType="customDropdownBtnForMinCount"
              required
              formGroupClassName="m-0"
            />
          </div>

          <div className="col-md-12">
            <RAFTextArea
              field="Mitigation"
              showLabel
              label="Provide a brief description or observations"
              placeholder="Description"
              rows={3}
              formGroupClassName="m-0"
            />
          </div>
        </>
      );
    } else if (riskType === RAFRiskRegisterType.Risk) {
      return (
        <>
          <div className="col-md-12">{getTypeActivityDropdown()}</div>
          <div className="col-md-12">
            <RAFDropdownCC
              field="RiskLevel"
              label="Risk Level"
              isColorOption
              displayDropdownType="customDropdownBtnForMinCount"
              uitype="colorpicker"
              moduleName={CareEsioEntity.RiskRegister.EntityName}
              required
              formGroupClassName="m-0"
            />
          </div>
          <div className="col-md-12">
            <RAFDropdownCC
              field="Likelihood"
              label="Likelihood"
              isColorOption
              displayDropdownType="customDropdownBtnForMinCount"
              uitype="colorpicker"
              moduleName={CareEsioEntity.RiskRegister.EntityName}
              //required
              formGroupClassName="m-0"
            />
          </div>
          {getSupportNeedsDropdown()}
          <div className="col-md-12">
            <RAFHtmlEditor
              field="Mitigation"
              label="Mitigation"
              rows={5}
              fullHeight={false}
              // required
              formGroupClassName="m-0"
            />
          </div>
        </>
      );
    }
  };

  const getTypeActivityDropdown = () => {
    if (props.riskType === RAFRiskRegisterType.HealthCondition) {
      let customFilter: RAFCustomFilter = {};
      customFilter.Condition = "and";
      customFilter.Rules = [];
      let filter: RAFCustomFilter = {};
      let filterVal: string[] = [];
      filterVal.push(RAFRiskRegisterType.HealthCondition);
      filter.Operator = RAFCustomOperator.Equal;
      filter.Value = filterVal;
      filter.Field = "Type";
      customFilter.Rules.push(filter);

      return (
        <RAFAutoCompleteMUI
          label="Select the type of condition"
          field="Title"
          placeholder="Select the type of condition"
          showLabel
          //showCreateButton
          url="StandardizedLibrary/LookUp"
          createform={RAFCreate}
          moduleName={RAFEntityName.StandardizedLibrary}
          customFilter={customFilter}
          SearchCreateOptionMode={"Footer"}
          required
          formGroupClassName="mb-0"
        />
      );
    } else if (props.riskType === RAFRiskRegisterType.Risk) {
      return (
        <RAFTextBox
          field="Title"
          label="Title"
          showLabel
          required
          formGroupClassName="mb-0"
        />
      );
    }
  };

  const riskRegisterContent = () => {
    return (
      <RAFEntityProvider moduleName={moduleName}>
        <RAFAttributeRelatedListProvider moduleName={moduleName}>
          <RAFAttributesContext.Consumer>
            {({ queryAttributes }) => {
              return (
                <RAFEntityContext.Consumer>
                  {({ entity, formLibrary }) => {
                    return (
                      <RAFObjectContextProvider
                        moduleName={moduleName}
                        objectId={props.objectUID}
                        progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}
                      >
                        <RAFObjectContext.Consumer>
                          {({ rafObject, saveObject }) => {
                            return (
                              <RAFForm
                                initialValues={rafObject}
                                formRef={(g) => {
                                  return (rafFormRef = g);
                                }}
                                onSubmit={(values) =>
                                  onSubmitObject(
                                    values,
                                    entity,
                                    queryAttributes,
                                    saveObject
                                  )
                                }
                                layout={RAFLayout.TwoColumnLayout}
                                className="h-100"
                              >
                                <div className="row g-3 gy-2">
                                  {" "}
                                  {getFormFields()}
                                </div>
                              </RAFForm>
                            );
                          }}
                        </RAFObjectContext.Consumer>
                      </RAFObjectContextProvider>
                    );
                  }}
                </RAFEntityContext.Consumer>
              );
            }}
          </RAFAttributesContext.Consumer>
        </RAFAttributeRelatedListProvider>
      </RAFEntityProvider>
    );
  };

  if (state.isActive) {
    if (state.isLoading === false) {
      return (
        <div className="h-100" id={outerDivId}>
          <div className="e-dlg-content-outer">
            <div className={`e-dlg-body-content`}>
              {" "}
              <div className="row g-3">{riskRegisterContent()}</div>
            </div>
            <div className="e-dlg-footerContent">
              <div className="w-100">
                <div className="row gx-2">
                  {isNotNullAndUndefined(props.onDelete) ? (
                    <div className="col-auto">
                      <RAFButtonComponent
                        action={RAFButtonConstant.Delete}
                        onClick={() => onClickDeleteRisk()}
                        idString="DeleteContent"
                        className="e-danger e-outline"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-auto ms-auto">
                    <RAFButtonComponent
                      isPrimary
                      action={RAFButtonConstant.Save}
                      onClick={() => rafFormRef && rafFormRef.form.submit()}
                      idString="EditContent"
                    />
                  </div>
                  <div className="col-auto">
                    <RAFButtonComponent
                      action={RAFButtonConstant.Cancel}
                      onClick={props.onClose}
                      idString="EditContent"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="container-fluid px-0">
          <ACLoadingPanel loadingText="Preparing Data..." />
        </div>
      );
    }
  } else {
    return <div></div>;
  }
}

export default React.memo(UpdateRisk);
